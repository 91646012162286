// Core React Imports
import React from "react";

// Local Imports
import UtilsUnit from "../../utils/UtilsUnit";
import { ParagraphText } from "../../styles/SharedStyles";

// CSS/Stylesheets
import "./WidgetProgressPercentCss.css";

const WidgetProgressPercent = ({ progress }) => {
  const utilsUnit = new UtilsUnit();

  const radius = 32;
  const strokeWidth = 7;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference * (1 - progress / 100);

  return (
    <div
      style={{
        position: "relative",
        width: "72px",
        height: "72px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg width="72" height="72" viewBox="0 0 72 72" style={{ transform: "rotate(-90deg)" }}>
        <circle cx="36" cy="36" r={radius} stroke="#F0F1F5" strokeWidth={strokeWidth} fill="none" />
        <circle
          cx="36"
          cy="36"
          r={radius}
          stroke="#46CDB3"
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transition: "stroke-dashoffset 0.3s ease" }}
        />
      </svg>
      <ParagraphText className="model-text-3" style={{ textAlign: "center" }}>
        {utilsUnit.formatNumberWithPercent(progress)}
      </ParagraphText>
    </div>
  );
};

export default WidgetProgressPercent;
