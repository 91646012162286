// Core React Imports
import React from "react";

// Local Imports
import { ParagraphText, WidgetMarginHeight, DivColumn } from "../../styles/SharedStyles";

// CSS/Stylesheets
import "./WidgetTextViewDataCss.css";

const WidgetTextViewData = ({ title, text, heightProp = "44px", widthProp = "auto" }) => {
  return (
    <DivColumn className="terminal-4-frame-9" style={{ height: heightProp, width: widthProp }}>
      <WidgetMarginHeight height="11px" />
      <ParagraphText className="terminal-text-16">{title}</ParagraphText>
      <ParagraphText className="terminal-text-17">{text}</ParagraphText>
    </DivColumn>
  );
};

export default WidgetTextViewData;
