// Core React Imports
import React, { useEffect } from "react";

// Third-Party Libraries
import { ReactSVG } from "react-svg";

// Local Imports
import { DivColumn, ParagraphText } from "../../../styles/SharedStyles";
import ConstantsImages from "../../../constants/ConstantsImages";

// CSS/Stylesheets
import "./WidgetAlertDialogSaveCss.css";

const WidgetAlertDialogSave = ({ isOpen, onCancel }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onCancel();
      }, 2500);

      // Cleanup the timer if the component unmounts or if isOpen changes
      return () => clearTimeout(timer);
    }
  }, [isOpen, onCancel]);

  if (!isOpen) return null;

  return (
    <div className="alert-dialog-background">
      <DivColumn className="alert-dialog-overlay-save">
        <ReactSVG src={ConstantsImages.APPROVE_IMAGE} />
        <DivColumn className="alert-dialog-overlay-2-save">
          <ParagraphText className="alert-dialog-overlay-text-save" style={{ textAlign: "center" }}>
            המודל נשמר וחושב מחדש
          </ParagraphText>
        </DivColumn>
      </DivColumn>
    </div>
  );
};

export default WidgetAlertDialogSave;
