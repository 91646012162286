// Core React Imports
import React from "react";

// Local Imports
import { DivColumn, ParagraphText } from "../../../styles/SharedStyles";

// CSS/Stylesheets
import "./WidgetInputCss.css";
import "../../../styles/SharedStylesCss.css";

const WidgetInput = ({ label, value, onChange, heightProp = "44px", widthProp = "100%", backgoundColortProp = "#e9eaee", fontSizeTitle = "12px", paddingProp = "0px 10px" }) => {
  return (
    <div className="terminal-4-frame-14" style={{ height: heightProp, width: widthProp }}>
      <div className="terminal-4-frame-15" style={{ backgroundColor: backgoundColortProp, padding: paddingProp }}>
        <DivColumn className="terminal-4-frame-16">
          <ParagraphText className="terminal-text-30" style={{ fontSize: fontSizeTitle }}>
            {label}
          </ParagraphText>
          <input type="text" className="text-edit" value={value} onChange={onChange} />
        </DivColumn>
      </div>
    </div>
  );
};

export default WidgetInput;
