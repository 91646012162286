// Core React Imports
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Third-Party Libraries
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

// Local Imports
import ApiService from "../../data/ApiService";
import UtilsUnit from "../../utils/UtilsUnit";
import { useLoading } from "../../widgets/loadings/WidgetLoading";
import ConstantsPages from "../../constants/ConstantsPages";
import ConstantsImages from "../../constants/ConstantsImages";
import { DivColumn, DivRow, WidgetMarginWidth, WidgetMarginHeight, ParagraphText } from "../../styles/SharedStyles";
import WidgetMap from "../../widgets/maps/WidgetMap";
import WidgetDropdown, { WidgetDropdownTypeEnum } from "../../widgets/dropdowns/WidgetDropdown";
import WidgetInput from "../../widgets/inputs/widget_input/WidgetInput";
import WidgetTextViewData from "../../widgets/texts/WidgetTextViewData";
import WidgetAlertDialogEdit from "../../widgets/alert_dialogs/widget_alert_dialog_edit/WidgetAlertDialogEdit";

// CSS/Stylesheets
import "./PageTerminalInfoCss.css";
import "../../styles/SharedStylesCss.css";

function PageTerminalInfo() {
  const apiService = new ApiService();
  const utilsUnit = new UtilsUnit();

  const location = useLocation();
  const terminalID = location.state?.terminalID;

  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const userData = useSelector((state) => state.user.userData);
  const [data, setData] = useState([]);
  const [dataIsReceived, setDataIsReceived] = useState(false);
  const [showUpdatesList, setShowUpdatesList] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isDialogEditOpen, setDialogEditOpen] = useState(false);

  // Input
  const [inputValueRequiredTerminalSize, setInputValueRequiredTerminalSize] = useState("");
  const [inputValueImportanceMeasure, setInputValueImportanceMeasure] = useState("");
  const [inputValuePotentialMeasure, setInputValuePotentialMeasure] = useState("");
  const [inputValueAreaInPlaningArea, setInputValueAreaInPlaningArea] = useState("");
  const [inputValueAreaNeedForPlaningArea, setInputValueAreaNeedForPlaningArea] = useState("");
  const [inputValueComments, setInputValueComments] = useState("");

  // Dropdown
  const [selectedTerminalTypeName, setSelectedTerminalTypeName] = useState(null);
  const [selectedWorkNeed, setSelectedWorkNeed] = useState(null);
  const [selectedUrgencyMeasureValue, setSelectedUrgencyMeasureValue] = useState(null);
  const [selectedTerminalStatusName, setSelectedTerminalStatusName] = useState(null);
  const [selectedRingByStrategicPlan, setSelectedRingByStrategicPlan] = useState(null);
  const [selectedApplicability, setSelectedApplicability] = useState(null);

  const jsonEditRef = useRef({});
  const jsonEditRefCheckChanges = useRef({});
  const showLoadingRef = useRef(showLoading);
  const hideLoadingRef = useRef(hideLoading);

  // Fetch data on component mount
  useEffect(() => {
    if (!utilsUnit.checkUserAuthorizationViewerEditor(userData)) return;

    fetchData();
  }, []);

  const fetchData = async () => {
    showLoadingRef.current();

    try {
      const fetchedData = await apiService.postTerminalInfo(terminalID);

      setData(fetchedData); // Set fetched data
      setDataToInputs(fetchedData);
      setJsonEditRefCheckChangesData();
      setDataIsReceived(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoadingRef.current();
    }
  };

  function setDataToInputs(data) {
    var nationalModelForTerminalData = data.nationalModelForTerminal_data;

    // Input
    setInputValueRequiredTerminalSize(nationalModelForTerminalData.sizeNeededForTerminalInAcre);
    setInputValueImportanceMeasure(nationalModelForTerminalData.numberOfTerminalsInArea);
    setInputValuePotentialMeasure(nationalModelForTerminalData.populationDensity2040);
    setInputValueAreaInPlaningArea(nationalModelForTerminalData.areaInPlaningArea);
    setInputValueAreaNeedForPlaningArea(nationalModelForTerminalData.areaNeedForPlaningArea);
    setInputValueComments(nationalModelForTerminalData.comments);

    // Dropdown
    setSelectedTerminalTypeName({
      terminalTypeName: nationalModelForTerminalData.terminalType,
    });
    setSelectedWorkNeed({
      workNeed: nationalModelForTerminalData.workNeed,
    });
    setSelectedUrgencyMeasureValue({
      urgencyMeasureValue: nationalModelForTerminalData.yearOfNeedGrade,
    });
    setSelectedTerminalStatusName({
      terminalStatusName: nationalModelForTerminalData.terminalStatus,
    });
    setSelectedRingByStrategicPlan({
      number: nationalModelForTerminalData.ringByStrategicPlan,
    });
    setSelectedApplicability({
      number: nationalModelForTerminalData.municipalityPosition,
    });

    // JSON
    jsonEditRef.current = {
      year_of_need_grade: nationalModelForTerminalData.yearOfNeedGrade,
      number_of_terminals_in_area: nationalModelForTerminalData.numberOfTerminalsInArea,
      population_density_2040: nationalModelForTerminalData.populationDensity2040,
      area_in_planning_area: nationalModelForTerminalData.areaInPlaningArea,
      area_need_for_planning_area: nationalModelForTerminalData.areaNeedForPlaningArea,
      size_needed_for_terminal_acre: nationalModelForTerminalData.sizeNeededForTerminalInAcre,
      ring_by_strategic_plan: nationalModelForTerminalData.ringByStrategicPlan,
      municipality_position: nationalModelForTerminalData.municipalityPosition,
      terminal_type: nationalModelForTerminalData.terminalType,
      work_need: nationalModelForTerminalData.workNeed,
      comments: nationalModelForTerminalData.comments,
      status: nationalModelForTerminalData.terminalStatus,
    };
  }

  function setJsonEditRefCheckChangesData() {
    jsonEditRefCheckChanges.current = { ...jsonEditRef.current };
  }

  const handleModelFrameClick = () => {
    if (editMode) return;

    navigate(ConstantsPages.PAGE_MODEL_INFO);
  };

  const handleShowUpdatesListClick = (value) => {
    setShowUpdatesList(value);
  };

  const handleSetEditModeClick = () => {
    if (editMode) {
      if (utilsUnit.areJSONsEqualLoose(jsonEditRefCheckChanges.current, jsonEditRef.current)) {
        setEditMode(false);
      } else {
        showEditAlertDialog();
      }
    } else {
      setEditMode(true);
    }
  };

  const handleSearchTerminalClick = () => {
    if (editMode) return;

    navigate(-1);
  };

  const showEditAlertDialog = () => {
    setDialogEditOpen(true);
  };

  const closeEditAlertDialog = (cancelEdit) => {
    setDialogEditOpen(false);

    if (cancelEdit) {
      setDataToInputs(data);
      setEditMode(false);
    }
  };

  var nationalModelForTerminalData = data.nationalModelForTerminal_data;
  var nationalModelForTerminalCount = data.nationalModelForTerminal_count;
  var secondaryMeasure = data.secondaryMeasure;
  var terminalTypesAndPrices = data.terminalTypesAndPrices;
  var nationalModelForTerminalDistinctWorkneed = data.nationalModelForTerminal_distinct_workneed;
  var changedTerminalID = data.changedTerminalID;
  var urgencyMeasure = data.urgencyMeasure;
  var terminalStatus = data.terminalStatus;

  var numbers1To4 = utilsUnit.generateNumberArray(1, 4);
  var numbers1To5 = utilsUnit.generateNumberArray(1, 5);

  const handleSelectWorkNeed = (option) => {
    handleSelectOption(option, "workNeed", setSelectedWorkNeed, "work_need");
  };

  const handleSelectTerminalTypeName = (option) => {
    handleSelectOption(option, "terminalTypeName", setSelectedTerminalTypeName, "terminal_type");
  };

  const handleSelectUrgencyMeasureValue = (option) => {
    handleSelectOption(option, "urgencyMeasureValue", setSelectedUrgencyMeasureValue, "year_of_need_grade");
  };

  const handleSelectTerminalStatusName = (option) => {
    handleSelectOption(option, "terminalStatusName", setSelectedTerminalStatusName, "status");
  };

  const handleSelectRingByStrategicPlan = (option) => {
    handleSelectOption(option, "number", setSelectedRingByStrategicPlan, "ring_by_strategic_plan");
  };

  const handleSelectApplicability = (option) => {
    handleSelectOption(option, "number", setSelectedApplicability, "municipality_position");
  };

  const handleChangeRequiredTerminalSize = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexDecimalOneNumber, setInputValueRequiredTerminalSize, "size_needed_for_terminal_acre");
  };

  const handleChangeImportanceMeasure = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueImportanceMeasure, "number_of_terminals_in_area");
  };

  const handleChangePotentialMeasure = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialMeasure, "population_density_2040");
  };

  const handleChangeAreaInPlaningArea = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexDecimalOneNumber, setInputValueAreaInPlaningArea, "area_in_planning_area");
  };

  const handleChangeAreaNeedForPlaningArea = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexDecimalOneNumber, setInputValueAreaNeedForPlaningArea, "area_need_for_planning_area");
  };

  const handleChangeComments = (event) => {
    handleChangeSimple(event, setInputValueComments, "comments");
  };

  const handleSelectOption = (option, key, setInputValue, jsonKey) => {
    const selectedObj = {
      [key]: option[key],
    };

    setInputValue(selectedObj);

    jsonEditRef.current[jsonKey] = option[key];
  };

  const handleChangeRegexNComma = (event, regex, setInputValue, jsonKey) => {
    const value = event.target.value;

    // Remove commas from the input value
    const valueWithoutComma = utilsUnit.removeCommas(value);

    // Validate the cleaned value
    if (regex.test(valueWithoutComma)) {
      // Update state with the cleaned value
      setInputValue(valueWithoutComma);

      // Update the corresponding JSON field
      jsonEditRef.current[jsonKey] = valueWithoutComma;
    }
  };

  const handleChangeSimple = (event, setInputValue, jsonKey) => {
    const value = event.target.value;

    setInputValue(value);

    jsonEditRef.current[jsonKey] = value;
  };

  const handleSaveTerminalChanges = async () => {
    showLoadingRef.current();

    try {
      jsonEditRef.current.terminalID = terminalID;
      jsonEditRef.current.userID = utilsUnit.getUserID(userData);
      jsonEditRef.current.userName = utilsUnit.getUserName(userData);

      const response = await apiService.postTerminalUpdateData(jsonEditRef.current);

      if (response.ok) {
        fetchData();
        setEditMode(false);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      hideLoadingRef.current();
    }
  };

  function getSecondaryMeasurePercentForDisplay(serial) {
    return utilsUnit.getMeasureValue(serial, secondaryMeasure, "secondaryMeasureSerial", "secondaryMeasurePercentForDisplay");
  }

  // Return JSX
  if (!dataIsReceived) {
    return <div />;
  }

  return (
    <div className="div-main-2-2">
      <DivColumn style={{ width: "1920px", height: "100vh" }}>
        <DivRow className="terminal-8-frame-2">
          {right_design_ui()}
          <DivColumn>
            <DivRow>
              {terminal_frame_ui()}
              <WidgetMarginWidth width="10px" />
              {terminal_frame_2_ui()}
            </DivRow>
            <WidgetMarginHeight height="11px" />
            <DivRow className="terminal-5-frame">
              {terminal_frame_3_ui()}
              {terminal_frame_4_ui()}
            </DivRow>
            <WidgetMarginHeight height="11px" />
            {terminal_frame_5_ui()}
          </DivColumn>
          {map_n_model_info_nav_ui()}
        </DivRow>
        <WidgetMarginHeight height="19px" />
        {comments_ui()}
      </DivColumn>
      {alert_dialog()}
    </div>
  );

  function right_design_ui() {
    return (
      <DivColumn className="terminal-7-frame" style={{ height: "904px" }}>
        <DivColumn className="terminal-7-frame-2">
          <ReactSVG src={ConstantsImages.TRANSPORTATION_SMALL_IMAGE} />
          <ParagraphText className="model-text-shared-1">מודל לתיעדוף מתקני תשתית לתפעול תח״צ באוטובוסים</ParagraphText>
        </DivColumn>
        <WidgetMarginHeight height="16px" />
        <ParagraphText className="terminal-text-32">נתוני מסוף</ParagraphText>
        <WidgetMarginHeight height="24px" />
        <div className="terminal-7-frame-4" style={{ cursor: editMode ? "default" : "pointer" }} onClick={handleSearchTerminalClick}>
          <div className="terminal-7-frame-3" style={{ backgroundColor: editMode ? "#C9CADE" : "#2b308b" }}>
            <ReactSVG src={ConstantsImages.SEARCH_IMAGE} />
          </div>
        </div>
        <WidgetMarginHeight height="24px" />
        {utilsUnit.editorUser(userData) && (
          <div className="terminal-7-frame-4" onClick={handleSetEditModeClick}>
            <div className="terminal-7-frame-5">
              <ReactSVG src={editMode ? ConstantsImages.CLOSE_2_IMAGE : ConstantsImages.EDIT_IMAGE} />
            </div>
          </div>
        )}
        {showUpdatesList ? (
          updates_list_general_ui()
        ) : (
          <div className="terminal-7-frame-6">
            <div className="terminal-7-frame-7" onClick={() => handleShowUpdatesListClick(true)}>
              <ReactSVG src={ConstantsImages.SIX_IMAGE} />
            </div>
          </div>
        )}
      </DivColumn>
    );
  }

  function updates_list_general_ui() {
    return (
      <DivColumn className="terminal-8-frame-3" style={{ display: "inline-flex", minWidth: "250px" }}>
        <DivRow className="terminal-8-frame-4">
          <div className="terminal-8-frame-9" onClick={() => handleShowUpdatesListClick(false)}>
            <ReactSVG src={ConstantsImages.CLOSE_IMAGE} />
          </div>
          <ParagraphText className="terminal-text-21">עדכונים</ParagraphText>
        </DivRow>
        <DivColumn className="terminal-8-frame-14">
          {changedTerminalID.map((changedTerminalIDItem, index) => (
            <DivColumn key={index}>
              <UpdatesItemUI changedTerminalIDItem={changedTerminalIDItem} />
              {index < changedTerminalID.length - 1 && <div className="terminal-8-divider" />}
            </DivColumn>
          ))}
        </DivColumn>
      </DivColumn>
    );
  }

  function UpdatesItemUI({ changedTerminalIDItem }) {
    return (
      <DivColumn className="terminal-8-frame-6">
        <DivColumn className="terminal-8-frame-7">
          <DivRow className="terminal-8-frame-8">
            <div className="terminal-8-frame-10">
              <ReactSVG src={ConstantsImages.SEVEN_IMAGE} />
            </div>
            <ParagraphText className="terminal-text-22">{utilsUnit.formatDate(changedTerminalIDItem.changeTimeStamp)}</ParagraphText>
            <ParagraphText className="terminal-text-23">{utilsUnit.formatDateHour(changedTerminalIDItem.changeTimeStamp)}</ParagraphText>
          </DivRow>
          <DivRow className="terminal-8-frame-8">
            <div className="terminal-8-frame-10">
              <ReactSVG src={ConstantsImages.PERSON_IMAGE} />
            </div>
            <ParagraphText className="terminal-text-24">{changedTerminalIDItem.userName}</ParagraphText>
          </DivRow>
        </DivColumn>
        <DivRow className="terminal-8-frame-8">
          <div className="terminal-8-frame-10">
            <ReactSVG src={ConstantsImages.EIGHT_IMAGE} />
          </div>
          <ParagraphText className="terminal-text-25">{changedTerminalIDItem.changedColumnNameHebrew}</ParagraphText>
        </DivRow>
        <DivRow className="terminal-8-frame-11">
          <div className="terminal-8-frame-12">
            <ParagraphText style={{ textAlign: "center" }} className="terminal-text-26">
              {changedTerminalIDItem.valueBefore}
            </ParagraphText>
          </div>
          <ReactSVG src={ConstantsImages.ARROW_LEFT_IMAGE} />
          <div className="terminal-8-frame-13">
            <ParagraphText style={{ textAlign: "center" }} className="terminal-text-27">
              {changedTerminalIDItem.valueAfter}
            </ParagraphText>
          </div>
        </DivRow>
      </DivColumn>
    );
  }

  function terminal_frame_ui() {
    return (
      <div className="terminal-frame">
        <DivRow>
          <DivColumn>
            <DivRow style={{ alignItems: "center" }}>
              <ReactSVG src={ConstantsImages.BUS_STATION_IMAGE} />
              <WidgetMarginWidth width="15px" />
              <DivColumn>
                <ParagraphText className="terminal-text-1">{nationalModelForTerminalData.terminalName}</ParagraphText>
                <ParagraphText className="terminal-text-2">{nationalModelForTerminalData.uniqueID}</ParagraphText>
              </DivColumn>
            </DivRow>
            <WidgetMarginHeight height="30px" />
            <DivRow style={{ alignItems: "center" }}>
              <ReactSVG src={ConstantsImages.MARKER_IMAGE} />
              <WidgetMarginWidth width="9px" />
              <ParagraphText className="terminal-text-3">{nationalModelForTerminalData.municipalityName}</ParagraphText>
              <WidgetMarginWidth width="30px" />
              {editMode ? terminal_frame_edit_mode_ui() : terminal_frame_view_mode_ui()}
            </DivRow>
          </DivColumn>
        </DivRow>
        <div className="terminal-frame-inner">
          <div className="terminal-frame-inner-2">
            <DivColumn>
              <ParagraphText className="terminal-text-4">ניקוד כולל</ParagraphText>
              <WidgetMarginHeight height="5px" />
              <ParagraphText className="terminal-text-5">{nationalModelForTerminalData.totalPoints}</ParagraphText>
            </DivColumn>
          </div>
        </div>
      </div>
    );
  }

  function terminal_frame_edit_mode_ui() {
    return (
      <WidgetDropdown
        options={terminalTypesAndPrices}
        labelKey="terminalTypeName"
        title="סוג מסוף"
        onSelect={handleSelectTerminalTypeName}
        selectedOptionProp={selectedTerminalTypeName}
        heightProp="48px"
        widthProp="151px"
        backgroundColorProp="#f0f1f580"
        titleColorProp="#fff"
        textColorProp="#fff"
        arrowImage={ConstantsImages.ARROW_WHITE_IMAGE}
        borderRadiusProp="10px"
        fontSizeTitle="12px"
      />
    );
  }

  function terminal_frame_view_mode_ui() {
    return (
      <>
        <ReactSVG src={ConstantsImages.ONE_IMAGE} />
        <WidgetMarginWidth width="9px" />
        <ParagraphText className="terminal-text-3">{nationalModelForTerminalData.terminalType}</ParagraphText>
      </>
    );
  }

  function terminal_frame_2_ui() {
    return (
      <DivColumn style={{ display: "block" }}>
        <DivRow className="terminal-2-frame" style={{ width: "250px" }}>
          <ReactSVG src={ConstantsImages.TWO_IMAGE} />
          <WidgetMarginWidth width="23px" />
          <DivColumn>
            <ParagraphText className="terminal-text-6">{nationalModelForTerminalData.terminalRank}</ParagraphText>
            <ParagraphText className="terminal-text-7">/{nationalModelForTerminalCount.count}</ParagraphText>
          </DivColumn>
        </DivRow>
        <WidgetMarginHeight height="3px" />
        <DivRow
          className="terminal-3-frame"
          style={{
            height: "73px",
            width: "250px",
            padding: editMode ? "8px 10px" : "8px 20px",
          }}
        >
          {editMode ? terminal_frame_2_edit_mode_ui() : terminal_frame_2_view_mode_ui()}
        </DivRow>
      </DivColumn>
    );
  }

  function terminal_frame_2_edit_mode_ui() {
    return (
      <DivRow
        style={{
          gap: "5px",
          width: "250px",
          display: "flex",
        }}
      >
        <WidgetDropdown
          options={nationalModelForTerminalDistinctWorkneed}
          labelKey="workNeed"
          title="נדרש"
          onSelect={handleSelectWorkNeed}
          selectedOptionProp={selectedWorkNeed}
          heightProp="53px"
          widthProp="100%"
          titleColorProp="#2B3361"
          textColorProp="#2B3361"
          backgroundColorProp="#f0f1f580"
          arrowImage={ConstantsImages.ARROW_SMALL_IMAGE}
          style={{ flex: 1 }}
          borderRadiusProp="10px"
          fontSizeTitle="12px"
        />
        <DivRow className="terminal-9-frame" style={{ flex: 1 }}>
          <div className="terminal-9-frame-2">
            <DivColumn className="terminal-9-frame-3">
              <ParagraphText className="terminal-text-28">גודל מסוף נדרש</ParagraphText>
              <input type="text" className="text-edit-3" value={utilsUnit.formatNumberWithCommas(inputValueRequiredTerminalSize)} onChange={handleChangeRequiredTerminalSize} />
            </DivColumn>
          </div>
        </DivRow>
      </DivRow>
    );
  }
  function terminal_frame_2_view_mode_ui() {
    return (
      <>
        <DivRow className="terminal-3-frame-2" style={{ width: "86px" }}>
          <ReactSVG src={ConstantsImages.CONE_IMAGE} />
          <DivColumn>
            <ParagraphText className="terminal-text-8">נדרש</ParagraphText>
            <ParagraphText className="terminal-text-9">{nationalModelForTerminalData.workNeed}</ParagraphText>
          </DivColumn>
        </DivRow>
        <DivColumn className="terminal-3-frame-3">
          <ParagraphText className="terminal-text-8">גודל מסוף נדרש</ParagraphText>
          <DivRow className="terminal-3-frame-5">
            <ParagraphText className="terminal-text-10">{utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.sizeNeededForTerminalInAcre)}</ParagraphText>
            <ParagraphText className="terminal-text-11">דונם</ParagraphText>
          </DivRow>
        </DivColumn>
      </>
    );
  }
  function terminal_frame_3_ui() {
    return (
      <DivColumn className="terminal-4-frame">
        <DivRow className="terminal-4-frame-2">
          <ReactSVG src={ConstantsImages.CAR_IMAGE} />
          <ParagraphText className="terminal-text-12">יעילות תחבורתית</ParagraphText>
        </DivRow>
        <DivColumn className="terminal-4-frame-3">
          <DivRow className="terminal-4-frame-4">{editMode ? terminal_frame_3_edit_mode_ui() : terminal_frame_3_view_mode_ui()}</DivRow>
          <DivColumn className="terminal-4-frame-12">
            <GaugeUI
              numberText={nationalModelForTerminalData.operationalGapsMeasure}
              label={"צמצום פער תפעולי"}
              percentLabel={getSecondaryMeasurePercentForDisplay(4)}
              widthTexts="536px"
            />
            <DivRow className="terminal-4-frame-11">
              {editMode ? terminal_frame_3_edit_mode_1_ui() : terminal_frame_3_view_mode_1_ui()}
              <WidgetTextViewData
                title={
                  <>
                    <span>הפרש בין שטח מתוכנן</span>
                    <br />
                    <span>לשטח קיים</span>
                  </>
                }
                text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.planedAreaDifference)}
                heightProp="53px"
                widthProp="155px"
              />
              <WidgetTextViewData
                title={
                  <>
                    <span>יחס בין גודל המתקן לבין</span>
                    <br />
                    <span>פער השטח הנדרש</span>
                  </>
                }
                text={utilsUnit.formatNumberWithPercent(nationalModelForTerminalData.ratioBetweenSizeNeededForTerminalAndPlanedAreaDifference)}
                heightProp="53px"
                widthProp="197px"
              />
            </DivRow>
          </DivColumn>
        </DivColumn>
      </DivColumn>
    );
  }

  function terminal_frame_3_edit_mode_ui() {
    return (
      <>
        <DivColumn className="terminal-4-frame-5">
          <GaugeUI numberText={nationalModelForTerminalData.urgencyMeasure} label={"דחיפות"} percentLabel={getSecondaryMeasurePercentForDisplay(1)} />
          <div className="terminal-4-frame-13" style={{ height: "49px", width: "218.3px" }}>
            <WidgetDropdown
              options={urgencyMeasure}
              labelKey="urgencyMeasureValue"
              title="שנת צורך תחבורתי"
              onSelect={handleSelectUrgencyMeasureValue}
              selectedOptionProp={selectedUrgencyMeasureValue}
              heightProp="44px"
              widthProp="auto"
              backgroundColorProp="#E9EAEE"
              titleColorProp="#5A81FA"
              textColorProp="#2B308B"
              arrowImage={ConstantsImages.ARROW_SMALL_IMAGE}
              borderRadiusProp="10px"
              fontSizeTitle="12px"
            />
          </div>
        </DivColumn>
        <DivColumn className="terminal-4-frame-5">
          <GaugeUI numberText={nationalModelForTerminalData.importanceMeasure} label={"חשיבות"} percentLabel={getSecondaryMeasurePercentForDisplay(2)} />
          <div className="terminal-4-frame-13" style={{ height: "49px", width: "218.3px" }}>
            <WidgetInput label='מתקנים נוספים במרחק 2 ק"מ' value={utilsUnit.formatNumberWithCommas(inputValueImportanceMeasure)} onChange={handleChangeImportanceMeasure} />
          </div>
        </DivColumn>
        <DivColumn className="terminal-4-frame-5">
          <GaugeUI numberText={nationalModelForTerminalData.potentialMeasure} label={"פוטנציאל"} percentLabel={getSecondaryMeasurePercentForDisplay(3)} />
          <div className="terminal-4-frame-13" style={{ height: "49px", width: "218.3px" }}>
            <WidgetInput label="צפיפות אוכלוסייה / מועסקים 2040" value={utilsUnit.formatNumberWithCommas(inputValuePotentialMeasure)} onChange={handleChangePotentialMeasure} />
          </div>
        </DivColumn>
      </>
    );
  }

  function terminal_frame_3_view_mode_ui() {
    return (
      <>
        <GaugeTerminalUI
          numberText={nationalModelForTerminalData.urgencyMeasure}
          terminalLabel={"דחיפות"}
          terminalPercentLabel={getSecondaryMeasurePercentForDisplay(1)}
          gaugeTitle="שנת צורך תחבורתי"
          gaugeText={nationalModelForTerminalData.yearOfNeedGrade}
        />
        <GaugeTerminalUI
          numberText={nationalModelForTerminalData.importanceMeasure}
          terminalLabel={"חשיבות"}
          terminalPercentLabel={getSecondaryMeasurePercentForDisplay(2)}
          gaugeTitle='מתקנים נוספים במרחק 2 ק"מ'
          gaugeText={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.numberOfTerminalsInArea)}
        />
        <GaugeTerminalUI
          numberText={nationalModelForTerminalData.potentialMeasure}
          terminalLabel={"פוטנציאל"}
          terminalPercentLabel={getSecondaryMeasurePercentForDisplay(3)}
          gaugeTitle="צפיפות אוכלוסייה / מועסקים 2040"
          gaugeText={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.populationDensity2040)}
        />
      </>
    );
  }

  function terminal_frame_3_edit_mode_1_ui() {
    return (
      <>
        <div className="terminal-4-frame-13" style={{ height: "53px", width: "154.6px" }}>
          <WidgetInput label="שטח קיים למרחב תכנון" value={utilsUnit.formatNumberWithCommas(inputValueAreaInPlaningArea)} onChange={handleChangeAreaInPlaningArea} />
        </div>
        <div className="terminal-4-frame-13" style={{ height: "53px", width: "154.6px" }}>
          <WidgetInput label="שטח נדרש למרחב תכנון" value={utilsUnit.formatNumberWithCommas(inputValueAreaNeedForPlaningArea)} onChange={handleChangeAreaNeedForPlaningArea} />
        </div>
      </>
    );
  }

  function terminal_frame_3_view_mode_1_ui() {
    return (
      <>
        <WidgetTextViewData
          title={
            <>
              <span>שטח קיים</span>
              <br />
              <span>למרחב תכנון</span>
            </>
          }
          text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.areaInPlaningArea)}
          heightProp="53px"
          widthProp="155px"
        />
        <WidgetTextViewData
          title={
            <>
              <span>שטח נדרש</span>
              <br />
              <span>למרחב תכנון</span>
            </>
          }
          text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.areaNeedForPlaningArea)}
          heightProp="53px"
          widthProp="155px"
        />
      </>
    );
  }

  function terminal_frame_4_ui() {
    return (
      <DivColumn className="terminal-5-frame-2">
        {editMode && (
          <DivColumn className="terminal-5-frame-11" onClick={handleSaveTerminalChanges}>
            <ParagraphText className="terminal-text-31">שמירה</ParagraphText>
          </DivColumn>
        )}
        <DivColumn className={editMode ? "terminal-5-frame-9" : "terminal-5-frame-3"} style={{ height: editMode ? "80px" : "136px" }}>
          {editMode ? terminal_frame_4_edit_mode_ui() : terminal_frame_4_view_mode_ui()}
        </DivColumn>
        <DivColumn
          className="terminal-5-frame-7"
          style={{
            height: editMode ? "153px" : "190px",
            padding: editMode ? "12px 20px 20px 20px" : "35px 20px 20px 20px",
          }}
        >
          <DivColumn className="terminal-5-frame-8">
            <DivRow className="terminal-4-frame-2">
              <ReactSVG src={ConstantsImages.FIVE_IMAGE} />
              <ParagraphText className="terminal-text-12">ישימות</ParagraphText>
            </DivRow>
          </DivColumn>
          {editMode ? terminal_frame_4_edit_mode_2_ui() : terminal_frame_4_view_mode_2_ui()}
          <DivColumn className="terminal-4-frame-9">
            <ParagraphText className="terminal-text-19">עמדת הרשות המקומית ומורכבות התיאומים</ParagraphText>
          </DivColumn>
        </DivColumn>
      </DivColumn>
    );
  }

  function terminal_frame_4_edit_mode_ui() {
    return (
      <>
        <DivRow className="terminal-5-frame-10">
          <ReactSVG src={ConstantsImages.NINE_IMAGE} />
          <ParagraphText className="terminal-text-29">{utilsUnit.formatDate(nationalModelForTerminalData.lastDateUpdated)}</ParagraphText>
        </DivRow>
        <WidgetDropdown
          options={terminalStatus}
          labelKey="terminalStatusName"
          title="סטטוס"
          onSelect={handleSelectTerminalStatusName}
          selectedOptionProp={selectedTerminalStatusName}
          heightProp="42px"
          backgroundColorProp="#f0f1f580"
          titleColorProp="#fff"
          textColorProp="#fff"
          arrowImage={ConstantsImages.ARROW_WHITE_IMAGE}
          borderRadiusProp="10px"
          fontSizeTitle="12px"
        />
      </>
    );
  }

  function terminal_frame_4_view_mode_ui() {
    return (
      <>
        <DivRow className="terminal-5-frame-4">
          <div className="terminal-5-frame-5">
            <ReactSVG src={ConstantsImages.THREE_IMAGE} />
          </div>
          <ParagraphText className="terminal-text-18">{utilsUnit.formatDate(nationalModelForTerminalData.lastDateUpdated)}</ParagraphText>
        </DivRow>
        <DivRow className="terminal-5-frame-4">
          <ReactSVG src={ConstantsImages.FOUR_IMAGE} />
          <ParagraphText className="terminal-text-34">{nationalModelForTerminalData.terminalStatus}</ParagraphText>
        </DivRow>
      </>
    );
  }

  function terminal_frame_4_edit_mode_2_ui() {
    return (
      <>
        <DivRow className="terminal-4-frame-6">
          <WidgetDropdown
            options={numbers1To5}
            labelKey="number"
            onSelect={handleSelectApplicability}
            selectedOptionProp={selectedApplicability}
            arrowImage={ConstantsImages.ARROW_WHITE_IMAGE}
            type={WidgetDropdownTypeEnum.NUMBER}
          />
          <DivColumn className="terminal-4-frame-8" style={{ width: "61px" }}>
            <ParagraphText className="terminal-text-14">ישימות</ParagraphText>
            <ParagraphText className="terminal-text-33">{getSecondaryMeasurePercentForDisplay(7)}</ParagraphText>
          </DivColumn>
          <ReactSVG src={utilsUnit.getGaugeImageSrc(nationalModelForTerminalData.municipalityPosition)} />
        </DivRow>
      </>
    );
  }

  function terminal_frame_4_view_mode_2_ui() {
    return (
      <>
        <GaugeUI numberText={nationalModelForTerminalData.municipalityPosition} label={"ישימות"} percentLabel={getSecondaryMeasurePercentForDisplay(7)} widthTexts="61px" />
      </>
    );
  }

  function terminal_frame_5_ui() {
    return (
      <div className="terminal-6-frame">
        <DivColumn className="terminal-6-frame-2">
          <DivRow className="terminal-4-frame-2">
            <ReactSVG src={ConstantsImages.COINS_IMAGE} />
            <ParagraphText className="terminal-text-12">יעילות כלכלית</ParagraphText>
          </DivRow>
          <DivColumn className="terminal-6-frame-3">
            <DivRow className="terminal-6-frame-4">
              <GaugeUI
                numberText={nationalModelForTerminalData.costPerTripMeasure}
                label={"עלות לנסיעה"}
                percentLabel={getSecondaryMeasurePercentForDisplay(6)}
                widthTexts="316px"
              />
              <GaugeUI
                numberText={nationalModelForTerminalData.costPerPassengerMeasure}
                label={"עלות לנוסע"}
                percentLabel={getSecondaryMeasurePercentForDisplay(5)}
                widthTexts="316px"
              />
            </DivRow>
            <DivColumn className="terminal-6-frame-5">
              <DivRow className="terminal-6-frame-6">
                <WidgetTextViewData
                  title="עלות מוערכת למסוף"
                  text={utilsUnit.formatNumberWithCommaWithShekel(nationalModelForTerminalData.costPerTerminalSizeNeededINS)}
                  widthProp="177px"
                />
                <WidgetTextViewData title="עלות מבנים" text={utilsUnit.formatNumberWithCommaWithShekel(nationalModelForTerminalData.costForBuildingsNIS)} widthProp="177px" />
                <WidgetTextViewData
                  title="עלות מוערכת להסדרי תנועה"
                  text={utilsUnit.formatNumberWithCommaWithShekel(nationalModelForTerminalData.costForTrafficArrangementsINS)}
                  widthProp="177px"
                />
                <WidgetTextViewData
                  title='עלות כוללת במלש"ח'
                  text={utilsUnit.formatNumberWithCommaWithShekel(nationalModelForTerminalData.totalCostOfTerminalInMNIS)}
                  widthProp="177px"
                />
                <WidgetTextViewData title="נוסעים" text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.passengers)} widthProp="177px" />
              </DivRow>
              <DivRow className="terminal-6-frame-6">
                <WidgetTextViewData title="פוטנציאל נסיעות לשעה" text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.potentialRidesPerHour)} widthProp="145px" />
                <WidgetTextViewData title="פוטנציאל נסיעות ליום" text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.potentialRidesPerDay)} widthProp="145px" />
                {editMode ? terminal_frame_5_edit_mode_ui() : terminal_frame_5_view_mode_ui()}
                <WidgetTextViewData title="מס' עמדות טעינה" text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.numberOfLoadingStations)} widthProp="145px" />
                <WidgetTextViewData title="מס' עמדות כולל" text={utilsUnit.formatNumberWithCommas(nationalModelForTerminalData.totalStations)} widthProp="145px" />
                <WidgetTextViewData title="עלות לנוסע" text={utilsUnit.formatNumberWithCommaWithShekel(nationalModelForTerminalData.costPerPassenger)} widthProp="145px" />
              </DivRow>
            </DivColumn>
          </DivColumn>
        </DivColumn>
      </div>
    );
  }

  function terminal_frame_5_edit_mode_ui() {
    return (
      <>
        <div className="terminal-4-frame-13" style={{ height: "52px", width: "145px" }}>
          <WidgetDropdown
            options={numbers1To4}
            labelKey="number"
            title="טבעת המסוף"
            onSelect={handleSelectRingByStrategicPlan}
            selectedOptionProp={selectedRingByStrategicPlan}
            heightProp="44px"
            widthProp="auto"
            backgroundColorProp="#E9EAEE"
            titleColorProp="#5A81FA"
            textColorProp="#2B308B"
            arrowImage={ConstantsImages.ARROW_SMALL_IMAGE}
            borderRadiusProp="10px"
            fontSizeTitle="12px"
            fontWeightText="800"
            fontFamilyText="Montserrat"
          />
        </div>
      </>
    );
  }

  function terminal_frame_5_view_mode_ui() {
    return (
      <>
        <WidgetTextViewData title="טבעת המסוף" text={nationalModelForTerminalData.ringByStrategicPlan} widthProp="145px" />
      </>
    );
  }

  function GaugeTerminalUI({ numberText, terminalLabel, terminalPercentLabel, gaugeTitle, gaugeText }) {
    return (
      <DivColumn className="terminal-4-frame-5">
        <GaugeUI numberText={numberText} label={terminalLabel} percentLabel={terminalPercentLabel} />
        <WidgetTextViewData title={gaugeTitle} text={gaugeText} />
      </DivColumn>
    );
  }

  function GaugeUI({ numberText, label, percentLabel, widthTexts = "70px" }) {
    return (
      <DivRow className="terminal-4-frame-6">
        <div
          className="terminal-4-frame-7"
          style={{
            backgroundColor: utilsUnit.getGaugeNumberBackgroundColor(numberText),
          }}
        >
          <ParagraphText
            className="terminal-text-13"
            style={{
              color: utilsUnit.getGaugeNumberTextColor(numberText),
            }}
          >
            {numberText}
          </ParagraphText>
        </div>
        <DivColumn className="terminal-4-frame-8" style={{ width: widthTexts }}>
          <ParagraphText className="terminal-text-14">{label}</ParagraphText>
          <ParagraphText className="terminal-text-33">{percentLabel}</ParagraphText>
        </DivColumn>
        <ReactSVG src={utilsUnit.getGaugeImageSrc(numberText)} />
      </DivRow>
    );
  }

  function map_n_model_info_nav_ui() {
    return (
      <div style={{ display: "flex" }}>
        <WidgetMap
          centerProp={{
            lat: nationalModelForTerminalData.latitude,
            lng: nationalModelForTerminalData.longitude,
          }}
          heightProp="904px"
          widthProp={showUpdatesList ? "353px" : "580px"}
          markerId={nationalModelForTerminalData.uniqueID}
          markerTitle={nationalModelForTerminalData.terminalName}
          markerLat={nationalModelForTerminalData.latitude}
          markerLng={nationalModelForTerminalData.longitude}
        />
        {utilsUnit.editorUser(userData) && (
          <div className="model-frame" style={{ opacity: editMode ? "0.5" : "1", cursor: editMode ? "default" : "pointer" }} onClick={handleModelFrameClick}>
            <ReactSVG src={ConstantsImages.MODEL_IMAGE} style={{ margin: "15px" }} />
          </div>
        )}
      </div>
    );
  }

  function comments_ui() {
    return editMode ? comments_edit_mode_ui() : comments_view_mode_ui();
  }

  function comments_edit_mode_ui() {
    return (
      <div className="terminal-8-frame-15">
        <div className="terminal-8-frame-16">
          <DivColumn className="terminal-8-frame-17">
            <ParagraphText className="terminal-text-30">הערות</ParagraphText>
            <input type="text" className="text-edit-2" value={inputValueComments} onChange={handleChangeComments} />
          </DivColumn>
        </div>
      </div>
    );
  }

  function comments_view_mode_ui() {
    return (
      <DivRow className="terminal-8-frame">
        <ReactSVG src={ConstantsImages.COMMENTS_IMAGE} />
        <ParagraphText className="terminal-text-20">{nationalModelForTerminalData.comments}</ParagraphText>
      </DivRow>
    );
  }

  function alert_dialog() {
    return <WidgetAlertDialogEdit isOpen={isDialogEditOpen} onApprove={() => closeEditAlertDialog(true)} onCancel={() => closeEditAlertDialog(false)} />;
  }
}

export default PageTerminalInfo;
