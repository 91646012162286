const ConstantsImages = {
  APPROVE_IMAGE: "/assets/images/approve_image.svg",
  ARROW_IMAGE: "/assets/images/arrow_image.svg",
  ARROW_LEFT_IMAGE: "/assets/images/arrow_left_image.svg",
  ARROW_SMALL_IMAGE: "/assets/images/arrow_small_image.svg",
  ARROW_WHITE_IMAGE: "/assets/images/arrow_white_image.svg",
  ARROW_WHITE_IMAGE: "/assets/images/arrow_white_image.svg",
  CAR_IMAGE: "/assets/images/car_image.svg",
  CLOSE_IMAGE: "/assets/images/close_image.svg",
  CLOSE_2_IMAGE: "/assets/images/close_2_image.svg",
  COINS_IMAGE: "/assets/images/coins_image.svg",
  COMMENTS_IMAGE: "/assets/images/comments_image.svg",
  CONE_IMAGE: "/assets/images/cone_image.svg",
  EDIT_IMAGE: "/assets/images/edit_image.svg",
  EIGHT_IMAGE: "/assets/images/eight_image.svg",
  FIVE_IMAGE: "/assets/images/five_image.svg",
  FOUR_IMAGE: "/assets/images/four_image.svg",
  GAUGE_1_IMAGE: "/assets/images/gauge_1_image.svg",
  GAUGE_2_IMAGE: "/assets/images/gauge_2_image.svg",
  GAUGE_3_IMAGE: "/assets/images/gauge_3_image.svg",
  GAUGE_4_IMAGE: "/assets/images/gauge_4_image.svg",
  GAUGE_5_IMAGE: "/assets/images/gauge_5_image.svg",
  MARKER_IMAGE: "/assets/images/marker_image.svg",
  MODEL_IMAGE: "/assets/images/model_image.svg",
  NINE_IMAGE: "/assets/images/nine_image.svg",
  ONE_IMAGE: "/assets/images/one_image.svg",
  PARKING_IMAGE: "/assets/images/parking_image.svg",
  PERSON_IMAGE: "/assets/images/person_image.svg",
  SEARCH_IMAGE: "/assets/images/search_image.svg",
  SEVEN_IMAGE: "/assets/images/seven_image.svg",
  SIX_IMAGE: "/assets/images/six_image.svg",
  THREE_IMAGE: "/assets/images/three_image.svg",
  TRANSPORTATION_IMAGE: "/assets/images/transportation_image.svg",
  TRANSPORTATION_SMALL_IMAGE: "/assets/images/transportation_small_image.svg",
  TWO_IMAGE: "/assets/images/two_image.svg",
};

export default ConstantsImages;
