// Core React Imports
import React, { useState, useEffect, useRef } from "react";

// Third-Party Libraries
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

// Local Imports
import ApiService from "../../data/ApiService";
import UtilsUnit from "../../utils/UtilsUnit";
import { useLoading } from "../../widgets/loadings/WidgetLoading";
import ConstantsImages from "../../constants/ConstantsImages";
import { DivColumn, DivRow, ParagraphText, WidgetMarginHeight, WidgetMarginWidth } from "../../styles/SharedStyles";
import WidgetInput from "../../widgets/inputs/widget_input/WidgetInput";
import WidgetInputSecond from "../../widgets/inputs/widget_input_second/WidgetInputSecond";
import WidgetInputsFromTo from "../../widgets/inputs/widget_inputs_from_to/WidgetInputsFromTo";
import WidgetTextViewData from "../../widgets/texts/WidgetTextViewData";
import WidgetAlertDialogEdit from "../../widgets/alert_dialogs/widget_alert_dialog_edit/WidgetAlertDialogEdit";
import WidgetAlertDialogSave from "../../widgets/alert_dialogs/widget_alert_dialog_save/WidgetAlertDialogSave";
import WidgetProgressPercent from "../../widgets/progresses/WidgetProgressPercent";
import WidgetInputPercent from "../../widgets/inputs/widget_input_percent/WidgetInputPercent";

// CSS/Stylesheets
import "./PageModelInfoCss.css";
import "../../styles/SharedStylesCss.css";

function PageModelInfo() {
  const apiService = new ApiService();
  const utilsUnit = new UtilsUnit();

  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const userData = useSelector((state) => state.user.userData);
  const [data, setData] = useState([]);
  const [dataIsReceived, setDataIsReceived] = useState(false);
  const [isDialogEditOpen, setDialogEditOpen] = useState(false);
  const [isDialogSaveOpen, setDialogSaveOpen] = useState(false);

  // Input
  const [inputValueLifeTime, setInputValueLifeTime] = useState("");
  const [inputValueBusinessDays, setInputValueBusinessDays] = useState("");
  const [inputValueMetropolitanRingOne, setInputValueMetropolitanRingOne] = useState("");
  const [inputValueMetropolitanRingTwo, setInputValueMetropolitanRingTwo] = useState("");
  const [inputValueMetropolitanRingThree, setInputValueMetropolitanRingThree] = useState("");
  const [inputValueMetropolitanRingFour, setInputValueMetropolitanRingFour] = useState("");
  const [inputValueCostPerAcre, setInputValueCostPerAcre] = useState("");

  // Input Percent
  const [inputValueUrgencyPercent, setInputValueUrgencyPercent] = useState("");
  const [inputValueImportanceServicePercent, setInputValueImportanceServicePercent] = useState("");
  const [inputValuePotentialPercent, setInputValuePotentialPercent] = useState("");
  const [inputValueReducingOperationalGapPercent, setInputValueReducingOperationalGapPercent] = useState("");
  const [inputValueCostPerPassengerPercent, setInputValueCostPerPassengerPercent] = useState("");
  const [inputValueCostPerTripPercent, setInputValueCostPerTripPercent] = useState("");
  const [inputValuePositionLocalAuthorityPercent, setInputValuePositionLocalAuthorityPercent] = useState("");

  // Input One
  const [inputValueUrgencyOne, setInputValueUrgencyOne] = useState("");
  const [inputValueImportanceServiceOneFrom, setInputValueImportanceServiceOneFrom] = useState("");
  const [inputValueImportanceServiceOneTo, setInputValueImportanceServiceOneTo] = useState("");
  const [inputValuePotentialOneFrom, setInputValuePotentialOneFrom] = useState("");
  const [inputValuePotentialOneTo, setInputValuePotentialOneTo] = useState("");
  const [inputValueReducingOperationalGapOneFrom, setInputValueReducingOperationalGapOneFrom] = useState("");
  const [inputValueReducingOperationalGapOneTo, setInputValueReducingOperationalGapOneTo] = useState("");
  const [inputValueCostPerPassengerOneFrom, setInputValueCostPerPassengerOneFrom] = useState("");
  const [inputValueCostPerPassengerOneTo, setInputValueCostPerPassengerOneTo] = useState("");
  const [inputValueCostPerTripOneFrom, setInputValueCostPerTripOneFrom] = useState("");
  const [inputValueCostPerTripOneTo, setInputValueCostPerTripOneTo] = useState("");

  // Input Two
  const [inputValueUrgencyTwo, setInputValueUrgencyTwo] = useState("");
  const [inputValueImportanceServiceTwoFrom, setInputValueImportanceServiceTwoFrom] = useState("");
  const [inputValueImportanceServiceTwoTo, setInputValueImportanceServiceTwoTo] = useState("");
  const [inputValuePotentialTwoFrom, setInputValuePotentialTwoFrom] = useState("");
  const [inputValuePotentialTwoTo, setInputValuePotentialTwoTo] = useState("");
  const [inputValueReducingOperationalGapTwoFrom, setInputValueReducingOperationalGapTwoFrom] = useState("");
  const [inputValueReducingOperationalGapTwoTo, setInputValueReducingOperationalGapTwoTo] = useState("");
  const [inputValueCostPerPassengerTwoFrom, setInputValueCostPerPassengerTwoFrom] = useState("");
  const [inputValueCostPerPassengerTwoTo, setInputValueCostPerPassengerTwoTo] = useState("");
  const [inputValueCostPerTripTwoFrom, setInputValueCostPerTripTwoFrom] = useState("");
  const [inputValueCostPerTripTwoTo, setInputValueCostPerTripTwoTo] = useState("");

  // Input Three
  const [inputValueUrgencyThree, setInputValueUrgencyThree] = useState("");
  const [inputValueImportanceServiceThreeFrom, setInputValueImportanceServiceThreeFrom] = useState("");
  const [inputValueImportanceServiceThreeTo, setInputValueImportanceServiceThreeTo] = useState("");
  const [inputValuePotentialThreeFrom, setInputValuePotentialThreeFrom] = useState("");
  const [inputValuePotentialThreeTo, setInputValuePotentialThreeTo] = useState("");
  const [inputValueReducingOperationalGapThreeFrom, setInputValueReducingOperationalGapThreeFrom] = useState("");
  const [inputValueReducingOperationalGapThreeTo, setInputValueReducingOperationalGapThreeTo] = useState("");
  const [inputValueCostPerPassengerThreeFrom, setInputValueCostPerPassengerThreeFrom] = useState("");
  const [inputValueCostPerPassengerThreeTo, setInputValueCostPerPassengerThreeTo] = useState("");
  const [inputValueCostPerTripThreeFrom, setInputValueCostPerTripThreeFrom] = useState("");
  const [inputValueCostPerTripThreeTo, setInputValueCostPerTripThreeTo] = useState("");

  // Input Four
  const [inputValueUrgencyFour, setInputValueUrgencyFour] = useState("");
  const [inputValueImportanceServiceFourFrom, setInputValueImportanceServiceFourFrom] = useState("");
  const [inputValueImportanceServiceFourTo, setInputValueImportanceServiceFourTo] = useState("");
  const [inputValuePotentialFourFrom, setInputValuePotentialFourFrom] = useState("");
  const [inputValuePotentialFourTo, setInputValuePotentialFourTo] = useState("");
  const [inputValueReducingOperationalGapFourFrom, setInputValueReducingOperationalGapFourFrom] = useState("");
  const [inputValueReducingOperationalGapFourTo, setInputValueReducingOperationalGapFourTo] = useState("");
  const [inputValueCostPerPassengerFourFrom, setInputValueCostPerPassengerFourFrom] = useState("");
  const [inputValueCostPerPassengerFourTo, setInputValueCostPerPassengerFourTo] = useState("");
  const [inputValueCostPerTripFourFrom, setInputValueCostPerTripFourFrom] = useState("");
  const [inputValueCostPerTripFourTo, setInputValueCostPerTripFourTo] = useState("");

  // Input Five
  const [inputValueUrgencyFive, setInputValueUrgencyFive] = useState("");
  const [inputValueImportanceServiceFiveFrom, setInputValueImportanceServiceFiveFrom] = useState("");
  const [inputValueImportanceServiceFiveTo, setInputValueImportanceServiceFiveTo] = useState("");
  const [inputValuePotentialFiveFrom, setInputValuePotentialFiveFrom] = useState("");
  const [inputValuePotentialFiveTo, setInputValuePotentialFiveTo] = useState("");
  const [inputValueReducingOperationalGapFiveFrom, setInputValueReducingOperationalGapFiveFrom] = useState("");
  const [inputValueReducingOperationalGapFiveTo, setInputValueReducingOperationalGapFiveTo] = useState("");
  const [inputValueCostPerPassengerFiveFrom, setInputValueCostPerPassengerFiveFrom] = useState("");
  const [inputValueCostPerPassengerFiveTo, setInputValueCostPerPassengerFiveTo] = useState("");
  const [inputValueCostPerTripFiveFrom, setInputValueCostPerTripFiveFrom] = useState("");
  const [inputValueCostPerTripFiveTo, setInputValueCostPerTripFiveTo] = useState("");

  // Input Costs
  // Input Costs One
  const [inputValueMonthsOfWorkOne, setInputValueMonthsOfWorkOne] = useState("");
  const [inputValueCostOfBuildingsOne, setInputValueCostOfBuildingsOne] = useState("");
  const [inputValueCostOfTrafficOne, setInputValueCostOfTrafficOne] = useState("");

  // Input Costs Two
  const [inputValuesetInpuMonthsOfWorkTwo, setInputValueMonthsOfWorkTwo] = useState("");
  const [inputValueCostOfBuildingsTwo, setInputValueCostOfBuildingsTwo] = useState("");
  const [inputValueCostOfTrafficTwo, setInputValueCostOfTrafficTwo] = useState("");

  // Input Costs Three
  const [inputValueMonthsOfWorkThree, setInputValueMonthsOfWorkThree] = useState("");
  const [inputValueCostOfBuildingsThree, setInputValueCostOfBuildingsThree] = useState("");
  const [inputValueCostOfTrafficThree, setInputValueCostOfTrafficThree] = useState("");

  // Input Costs Four
  const [inputValueMonthsOfWorkFour, setInputValueMonthsOfWorkFour] = useState("");
  const [inputValueCostOfBuildingsFour, setInputValueCostOfBuildingsFour] = useState("");
  const [inputValueCostOfTrafficFour, setInputValueCostOfTrafficFour] = useState("");

  // Input Costs Five
  const [inputValueMonthsOfWorkFive, setInputValueMonthsOfWorkFive] = useState("");
  const [inputValueCostOfBuildingsFive, setInputValueCostOfBuildingsFive] = useState("");
  const [inputValueCostOfTrafficFive, setInputValueCostOfTrafficFive] = useState("");

  const jsonEditRef = useRef({});
  const jsonEditRefCheckChanges = useRef({});
  const showLoadingRef = useRef(showLoading);
  const hideLoadingRef = useRef(hideLoading);

  const workMonthsText = "חודשי עבודה";
  const buildingCostText = "עלות מבנים";
  const monthlyTrafficArrangementCostText = "עלות הסדרי תנועה לחודש";
  const totalTrafficArrangementCostText = "עלות כללית להסדרי תנועה";

  // Fetch data on component mount
  useEffect(() => {
    if (!utilsUnit.checkUserAuthorizationEditor(userData)) return;

    fetchData();
  }, []);

  const fetchData = async () => {
    showLoadingRef.current();

    try {
      const fetchedData = await apiService.getModelParameters();

      setData(fetchedData); // Set fetched data
      setDataToInputs(fetchedData);
      setJsonEditRefCheckChangesData();
      setDataIsReceived(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoadingRef.current();
    }
  };

  function setDataToInputs(data) {
    var secondaryMeasure = data.secondaryMeasure;
    var potentialMeasure = data.potentialMeasure;
    var operationalGapsMeasure = data.operationalGapsMeasure;
    var importanceMeasure = data.importanceMeasure;
    var urgencyMeasure = data.urgencyMeasure;
    var costPerPassengerMeasure = data.costPerPassengerMeasure;
    var costPerTripMeasure = data.costPerTripMeasure;
    var fillFactorByRing = data.fillFactorByRing;
    var terminalTypesAndPrices = data.terminalTypesAndPrices;
    var economicsParameters = data.economicsParameters;

    // secondaryMeasure
    setInputValueUrgencyPercent(getSecondaryMeasurePercent(secondaryMeasure, 1));
    setInputValueImportanceServicePercent(getSecondaryMeasurePercent(secondaryMeasure, 2));
    setInputValuePotentialPercent(getSecondaryMeasurePercent(secondaryMeasure, 3));
    setInputValueReducingOperationalGapPercent(getSecondaryMeasurePercent(secondaryMeasure, 4));
    setInputValueCostPerPassengerPercent(getSecondaryMeasurePercent(secondaryMeasure, 5));
    setInputValueCostPerTripPercent(getSecondaryMeasurePercent(secondaryMeasure, 6));
    setInputValuePositionLocalAuthorityPercent(getSecondaryMeasurePercent(secondaryMeasure, 7));

    // potentialMeasure - from
    setInputValuePotentialOneFrom(getFromPopulation(potentialMeasure, 1));
    setInputValuePotentialTwoFrom(getFromPopulation(potentialMeasure, 2));
    setInputValuePotentialThreeFrom(getFromPopulation(potentialMeasure, 3));
    setInputValuePotentialFourFrom(getFromPopulation(potentialMeasure, 4));
    setInputValuePotentialFiveFrom(getFromPopulation(potentialMeasure, 5));

    // potentialMeasure - to
    setInputValuePotentialOneTo(getToPopulation(potentialMeasure, 1));
    setInputValuePotentialTwoTo(getToPopulation(potentialMeasure, 2));
    setInputValuePotentialThreeTo(getToPopulation(potentialMeasure, 3));
    setInputValuePotentialFourTo(getToPopulation(potentialMeasure, 4));
    setInputValuePotentialFiveTo(getToPopulation(potentialMeasure, 5));

    // operationalGapsMeasure - from
    setInputValueReducingOperationalGapOneFrom(getFromRatio(operationalGapsMeasure, 1));
    setInputValueReducingOperationalGapTwoFrom(getFromRatio(operationalGapsMeasure, 2));
    setInputValueReducingOperationalGapThreeFrom(getFromRatio(operationalGapsMeasure, 3));
    setInputValueReducingOperationalGapFourFrom(getFromRatio(operationalGapsMeasure, 4));
    setInputValueReducingOperationalGapFiveFrom(getFromRatio(operationalGapsMeasure, 5));

    // operationalGapsMeasure - to
    setInputValueReducingOperationalGapOneTo(getToRatio(operationalGapsMeasure, 1));
    setInputValueReducingOperationalGapTwoTo(getToRatio(operationalGapsMeasure, 2));
    setInputValueReducingOperationalGapThreeTo(getToRatio(operationalGapsMeasure, 3));
    setInputValueReducingOperationalGapFourTo(getToRatio(operationalGapsMeasure, 4));
    setInputValueReducingOperationalGapFiveTo(getToRatio(operationalGapsMeasure, 5));

    // importanceMeasure - from
    setInputValueImportanceServiceOneFrom(getFromNumberOfTerminalsInArea(importanceMeasure, 1));
    setInputValueImportanceServiceTwoFrom(getFromNumberOfTerminalsInArea(importanceMeasure, 2));
    setInputValueImportanceServiceThreeFrom(getFromNumberOfTerminalsInArea(importanceMeasure, 3));
    setInputValueImportanceServiceFourFrom(getFromNumberOfTerminalsInArea(importanceMeasure, 4));
    setInputValueImportanceServiceFiveFrom(getFromNumberOfTerminalsInArea(importanceMeasure, 5));

    // importanceMeasure - to
    setInputValueImportanceServiceOneTo(getToNumberOfTerminalsInArea(importanceMeasure, 1));
    setInputValueImportanceServiceTwoTo(getToNumberOfTerminalsInArea(importanceMeasure, 2));
    setInputValueImportanceServiceThreeTo(getToNumberOfTerminalsInArea(importanceMeasure, 3));
    setInputValueImportanceServiceFourTo(getToNumberOfTerminalsInArea(importanceMeasure, 4));
    setInputValueImportanceServiceFiveTo(getToNumberOfTerminalsInArea(importanceMeasure, 5));

    // urgencyMeasure
    setInputValueUrgencyOne(getUrgencyMeasureValue(urgencyMeasure, 1));
    setInputValueUrgencyTwo(getUrgencyMeasureValue(urgencyMeasure, 2));
    setInputValueUrgencyThree(getUrgencyMeasureValue(urgencyMeasure, 3));
    setInputValueUrgencyFour(getUrgencyMeasureValue(urgencyMeasure, 4));
    setInputValueUrgencyFive(getUrgencyMeasureValue(urgencyMeasure, 5));

    // costPerPassengerMeasure - from
    setInputValueCostPerPassengerOneFrom(getFromCostPerPassenger(costPerPassengerMeasure, 1));
    setInputValueCostPerPassengerTwoFrom(getFromCostPerPassenger(costPerPassengerMeasure, 2));
    setInputValueCostPerPassengerThreeFrom(getFromCostPerPassenger(costPerPassengerMeasure, 3));
    setInputValueCostPerPassengerFourFrom(getFromCostPerPassenger(costPerPassengerMeasure, 4));
    setInputValueCostPerPassengerFiveFrom(getFromCostPerPassenger(costPerPassengerMeasure, 5));

    // costPerPassengerMeasure - to
    setInputValueCostPerPassengerOneTo(getToCostPerPassenger(costPerPassengerMeasure, 1));
    setInputValueCostPerPassengerTwoTo(getToCostPerPassenger(costPerPassengerMeasure, 2));
    setInputValueCostPerPassengerThreeTo(getToCostPerPassenger(costPerPassengerMeasure, 3));
    setInputValueCostPerPassengerFourTo(getToCostPerPassenger(costPerPassengerMeasure, 4));
    setInputValueCostPerPassengerFiveTo(getToCostPerPassenger(costPerPassengerMeasure, 5));

    // costPerTripMeasure - from
    setInputValueCostPerTripOneFrom(getFromCostPerTrip(costPerTripMeasure, 1));
    setInputValueCostPerTripTwoFrom(getFromCostPerTrip(costPerTripMeasure, 2));
    setInputValueCostPerTripThreeFrom(getFromCostPerTrip(costPerTripMeasure, 3));
    setInputValueCostPerTripFourFrom(getFromCostPerTrip(costPerTripMeasure, 4));
    setInputValueCostPerTripFiveFrom(getFromCostPerTrip(costPerTripMeasure, 5));

    // costPerTripMeasure - to
    setInputValueCostPerTripOneTo(getToCostPerTrip(costPerTripMeasure, 1));
    setInputValueCostPerTripTwoTo(getToCostPerTrip(costPerTripMeasure, 2));
    setInputValueCostPerTripThreeTo(getToCostPerTrip(costPerTripMeasure, 3));
    setInputValueCostPerTripFourTo(getToCostPerTrip(costPerTripMeasure, 4));
    setInputValueCostPerTripFiveTo(getToCostPerTrip(costPerTripMeasure, 5));

    // fillFactorByRing
    setInputValueMetropolitanRingOne(getFillFactor(fillFactorByRing, 1));
    setInputValueMetropolitanRingTwo(getFillFactor(fillFactorByRing, 2));
    setInputValueMetropolitanRingThree(getFillFactor(fillFactorByRing, 3));
    setInputValueMetropolitanRingFour(getFillFactor(fillFactorByRing, 4));

    // terminalTypesAndPrices - pricePerAcreNIS
    setInputValueCostPerAcre(terminalTypesAndPrices[0].pricePerAcreNIS);

    // terminalTypesAndPrices - monthsOfWork
    setInputValueMonthsOfWorkOne(getMonthsOfWork(terminalTypesAndPrices, 1));
    setInputValueMonthsOfWorkTwo(getMonthsOfWork(terminalTypesAndPrices, 2));
    setInputValueMonthsOfWorkThree(getMonthsOfWork(terminalTypesAndPrices, 3));
    setInputValueMonthsOfWorkFour(getMonthsOfWork(terminalTypesAndPrices, 4));
    setInputValueMonthsOfWorkFive(getMonthsOfWork(terminalTypesAndPrices, 5));

    // terminalTypesAndPrices - priceForBuildingsNIS
    setInputValueCostOfBuildingsOne(getPriceForBuildingsNIS(terminalTypesAndPrices, 1));
    setInputValueCostOfBuildingsTwo(getPriceForBuildingsNIS(terminalTypesAndPrices, 2));
    setInputValueCostOfBuildingsThree(getPriceForBuildingsNIS(terminalTypesAndPrices, 3));
    setInputValueCostOfBuildingsFour(getPriceForBuildingsNIS(terminalTypesAndPrices, 4));
    setInputValueCostOfBuildingsFive(getPriceForBuildingsNIS(terminalTypesAndPrices, 5));

    // terminalTypesAndPrices - priceForTrafficArrangementsPerMonthNIS
    setInputValueCostOfTrafficOne(getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 1));
    setInputValueCostOfTrafficTwo(getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 2));
    setInputValueCostOfTrafficThree(getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 3));
    setInputValueCostOfTrafficFour(getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 4));
    setInputValueCostOfTrafficFive(getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 5));

    // economicsParameters
    setInputValueLifeTime(getEconomicsParametersParameter(economicsParameters, 2));
    setInputValueBusinessDays(getEconomicsParametersParameter(economicsParameters, 1));

    // JSON
    jsonEditRef.current = {
      // secondaryMeasure
      urgency_percent: getSecondaryMeasurePercent(secondaryMeasure, 1),
      importance_service_percent: getSecondaryMeasurePercent(secondaryMeasure, 2),
      potential_percent: getSecondaryMeasurePercent(secondaryMeasure, 3),
      reducing_operational_gap_percent: getSecondaryMeasurePercent(secondaryMeasure, 4),
      cost_per_passenger_percent: getSecondaryMeasurePercent(secondaryMeasure, 5),
      cost_per_trip_percent: getSecondaryMeasurePercent(secondaryMeasure, 6),
      position_local_authority_percent: getSecondaryMeasurePercent(secondaryMeasure, 7),

      // potentialMeasure
      potential_one_from: getFromPopulation(potentialMeasure, 1),
      potential_one_to: getToPopulation(potentialMeasure, 1),
      potential_two_from: getFromPopulation(potentialMeasure, 2),
      potential_two_to: getToPopulation(potentialMeasure, 2),
      potential_three_from: getFromPopulation(potentialMeasure, 3),
      potential_three_to: getToPopulation(potentialMeasure, 3),
      potential_four_from: getFromPopulation(potentialMeasure, 4),
      potential_four_to: getToPopulation(potentialMeasure, 4),
      potential_five_from: getFromPopulation(potentialMeasure, 5),
      potential_five_to: getToPopulation(potentialMeasure, 5),

      // operationalGapsMeasure
      reducing_operational_gap_one_from: getFromRatio(operationalGapsMeasure, 1),
      reducing_operational_gap_one_to: getToRatio(operationalGapsMeasure, 1),
      reducing_operational_gap_two_from: getFromRatio(operationalGapsMeasure, 2),
      reducing_operational_gap_two_to: getToRatio(operationalGapsMeasure, 2),
      reducing_operational_gap_three_from: getFromRatio(operationalGapsMeasure, 3),
      reducing_operational_gap_three_to: getToRatio(operationalGapsMeasure, 3),
      reducing_operational_gap_four_from: getFromRatio(operationalGapsMeasure, 4),
      reducing_operational_gap_four_to: getToRatio(operationalGapsMeasure, 4),
      reducing_operational_gap_five_from: getFromRatio(operationalGapsMeasure, 5),
      reducing_operational_gap_five_to: getToRatio(operationalGapsMeasure, 5),

      // importanceMeasure
      importance_service_one_from: getFromNumberOfTerminalsInArea(importanceMeasure, 1),
      importance_service_one_to: getToNumberOfTerminalsInArea(importanceMeasure, 1),
      importance_service_two_from: getFromNumberOfTerminalsInArea(importanceMeasure, 2),
      importance_service_two_to: getToNumberOfTerminalsInArea(importanceMeasure, 2),
      importance_service_three_from: getFromNumberOfTerminalsInArea(importanceMeasure, 3),
      importance_service_three_to: getToNumberOfTerminalsInArea(importanceMeasure, 3),
      importance_service_four_from: getFromNumberOfTerminalsInArea(importanceMeasure, 4),
      importance_service_four_to: getToNumberOfTerminalsInArea(importanceMeasure, 4),
      importance_service_five_from: getFromNumberOfTerminalsInArea(importanceMeasure, 5),
      importance_service_five_to: getToNumberOfTerminalsInArea(importanceMeasure, 5),

      // urgencyMeasur
      urgency_one: getUrgencyMeasureValue(urgencyMeasure, 1),
      urgency_two: getUrgencyMeasureValue(urgencyMeasure, 2),
      urgency_three: getUrgencyMeasureValue(urgencyMeasure, 3),
      urgency_four: getUrgencyMeasureValue(urgencyMeasure, 4),
      urgency_five: getUrgencyMeasureValue(urgencyMeasure, 5),

      // costPerPassengerMeasure
      cost_per_passenger_one_from: getFromCostPerPassenger(costPerPassengerMeasure, 1),
      cost_per_passenger_one_to: getToCostPerPassenger(costPerPassengerMeasure, 1),
      cost_per_passenger_two_from: getFromCostPerPassenger(costPerPassengerMeasure, 2),
      cost_per_passenger_two_to: getToCostPerPassenger(costPerPassengerMeasure, 2),
      cost_per_passenger_three_from: getFromCostPerPassenger(costPerPassengerMeasure, 3),
      cost_per_passenger_three_to: getToCostPerPassenger(costPerPassengerMeasure, 3),
      cost_per_passenger_four_from: getFromCostPerPassenger(costPerPassengerMeasure, 4),
      cost_per_passenger_four_to: getToCostPerPassenger(costPerPassengerMeasure, 4),
      cost_per_passenger_five_from: getFromCostPerPassenger(costPerPassengerMeasure, 5),
      cost_per_passenger_five_to: getToCostPerPassenger(costPerPassengerMeasure, 5),

      // costPerTripMeasure
      cost_per_trip_one_from: getFromCostPerTrip(costPerTripMeasure, 1),
      cost_per_trip_one_to: getToCostPerTrip(costPerTripMeasure, 1),
      cost_per_trip_two_from: getFromCostPerTrip(costPerTripMeasure, 2),
      cost_per_trip_two_to: getToCostPerTrip(costPerTripMeasure, 2),
      cost_per_trip_three_from: getFromCostPerTrip(costPerTripMeasure, 3),
      cost_per_trip_three_to: getToCostPerTrip(costPerTripMeasure, 3),
      cost_per_trip_four_from: getFromCostPerTrip(costPerTripMeasure, 4),
      cost_per_trip_four_to: getToCostPerTrip(costPerTripMeasure, 4),
      cost_per_trip_five_from: getFromCostPerTrip(costPerTripMeasure, 5),
      cost_per_trip_five_to: getToCostPerTrip(costPerTripMeasure, 5),

      // fillFactorByRing
      metropolitan_ring_one: getFillFactor(fillFactorByRing, 1),
      metropolitan_ring_two: getFillFactor(fillFactorByRing, 2),
      metropolitan_ring_three: getFillFactor(fillFactorByRing, 3),
      metropolitan_ring_four: getFillFactor(fillFactorByRing, 4),

      // terminalTypesAndPrices
      cost_per_acre: terminalTypesAndPrices[0].pricePerAcreNIS,
      months_of_work_one: getMonthsOfWork(terminalTypesAndPrices, 1),
      cost_of_buildings_one: getPriceForBuildingsNIS(terminalTypesAndPrices, 1),
      cost_of_traffic_one: getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 1),
      months_of_work_two: getMonthsOfWork(terminalTypesAndPrices, 2),
      cost_of_buildings_two: getPriceForBuildingsNIS(terminalTypesAndPrices, 2),
      cost_of_traffic_two: getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 2),
      months_of_work_three: getMonthsOfWork(terminalTypesAndPrices, 3),
      cost_of_buildings_three: getPriceForBuildingsNIS(terminalTypesAndPrices, 3),
      cost_of_traffic_three: getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 3),
      months_of_work_four: getMonthsOfWork(terminalTypesAndPrices, 4),
      cost_of_buildings_four: getPriceForBuildingsNIS(terminalTypesAndPrices, 4),
      cost_of_traffic_four: getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 4),
      months_of_work_five: getMonthsOfWork(terminalTypesAndPrices, 5),
      cost_of_buildings_five: getPriceForBuildingsNIS(terminalTypesAndPrices, 5),
      cost_of_traffic_five: getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, 5),

      // economicsParameters
      life_time: getEconomicsParametersParameter(economicsParameters, 2),
      business_days: getEconomicsParametersParameter(economicsParameters, 1),
    };
  }

  function setJsonEditRefCheckChangesData() {
    jsonEditRefCheckChanges.current = { ...jsonEditRef.current };
  }

  var terminalTypesAndPrices = data.terminalTypesAndPrices;
  var mainMeasure = data.mainMeasure;

  // Get value by serial
  function getSecondaryMeasurePercent(secondaryMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, secondaryMeasure, "secondaryMeasureSerial", "secondaryMeasurePercent");
  }

  function getFromPopulation(potentialMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, potentialMeasure, "potentialMeasureGrade", "fromPopulation");
  }

  function getToPopulation(potentialMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, potentialMeasure, "potentialMeasureGrade", "toPopulation");
  }

  function getFromRatio(operationalGapsMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, operationalGapsMeasure, "operationalGapsGrade", "fromRatio");
  }

  function getToRatio(operationalGapsMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, operationalGapsMeasure, "operationalGapsGrade", "toRatio");
  }

  function getFromNumberOfTerminalsInArea(importanceMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, importanceMeasure, "importanceGrade", "fromNumberOfTerminalsInArea");
  }

  function getToNumberOfTerminalsInArea(importanceMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, importanceMeasure, "importanceGrade", "toNumberOfTerminalsInArea");
  }

  function getUrgencyMeasureValue(urgencyMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, urgencyMeasure, "urgencyMeasureGrade", "urgencyMeasureValue");
  }

  function getFromCostPerPassenger(costPerPassengerMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, costPerPassengerMeasure, "costPerPassengerGrade", "fromCostPerPassenger");
  }

  function getToCostPerPassenger(costPerPassengerMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, costPerPassengerMeasure, "costPerPassengerGrade", "toCostPerPassenger");
  }

  function getFromCostPerTrip(costPerTripMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, costPerTripMeasure, "costPerTripGrade", "fromCostPerTrip");
  }

  function getToCostPerTrip(costPerTripMeasure, serial) {
    return utilsUnit.getMeasureValue(serial, costPerTripMeasure, "costPerTripGrade", "toCostPerTrip");
  }

  function getFillFactor(fillFactorByRing, serial) {
    return utilsUnit.getMeasureValue(serial, fillFactorByRing, "ringINT", "fillFactor");
  }

  function getEconomicsParametersParameter(economicsParameters, serial) {
    return utilsUnit.getMeasureValue(serial, economicsParameters, "parameterSerial", "parameter");
  }

  function getMonthsOfWork(terminalTypesAndPrices, serial) {
    return utilsUnit.getMeasureValue(serial, terminalTypesAndPrices, "terminalTypeSerial", "monthsOfWork");
  }

  function getPriceForBuildingsNIS(terminalTypesAndPrices, serial) {
    return utilsUnit.getMeasureValue(serial, terminalTypesAndPrices, "terminalTypeSerial", "priceForBuildingsNIS");
  }

  function getPriceForTrafficArrangementsPerMonthNIS(terminalTypesAndPrices, serial) {
    return utilsUnit.getMeasureValue(serial, terminalTypesAndPrices, "terminalTypeSerial", "priceForTrafficArrangementsPerMonthNIS");
  }

  function getTotalPriceForTrafficArrangementsPerTerminalTypeNIS(serial) {
    return utilsUnit.getMeasureValue(serial, terminalTypesAndPrices, "terminalTypeSerial", "TotalPriceForTrafficArrangementsPerTerminalTypeNIS");
  }

  function getMainMeasurePercent(serial) {
    return utilsUnit.getMeasureValue(serial, mainMeasure, "mainMeasureSerial", "mainMeasurePercent");
  }

  // handle
  const handleSaveModelChanges = async () => {
    if (!handleValidations()) return;

    showLoadingRef.current();

    try {
      const response = await apiService.postModelParametersUpdateData(jsonEditRef.current);

      if (response.ok) {
        showSaveAlertDialog();
      }

      showSaveAlertDialog();
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      hideLoadingRef.current();
    }
  };

  function handleValidations() {
    var isValid = true;

    if (!handleFirstValidation()) {
      isValid = false;
    } else if (!handleSecondValidation()) {
      isValid = false;
    } else if (!handleThirdValidation()) {
      isValid = false;
    } else if (!handleFourthValidation()) {
      isValid = false;
    } else if (!handleFifthValidation()) {
      isValid = false;
    } else if (!handleSixthValidation()) {
      isValid = false;
    }

    return isValid;
  }

  function handleFirstValidation() {
    var isValid = true;

    var errorFirstLine = 'סה"כ האחוזים של שבעת מדדי המודל אינם מסתכמים ל-100%.';
    var errorSecondLine = "המודל אינו נשמר, יש לתקנו ולשמור אותו מחדש.";

    const urgencyPercent = Number(inputValueUrgencyPercent);
    const importanceServicePercent = Number(inputValueImportanceServicePercent);
    const potentialPercent = Number(inputValuePotentialPercent);
    const reducingOperationalGapPercent = Number(inputValueReducingOperationalGapPercent);
    const costPerPassengerPercent = Number(inputValueCostPerPassengerPercent);
    const costPerTripPercent = Number(inputValueCostPerTripPercent);
    const positionLocalAuthorityPercent = Number(inputValuePositionLocalAuthorityPercent);

    const sumValues =
      urgencyPercent + importanceServicePercent + potentialPercent + reducingOperationalGapPercent + costPerPassengerPercent + costPerTripPercent + positionLocalAuthorityPercent;

    if (sumValues !== 100) {
      alert(errorFirstLine + "\n" + errorSecondLine);

      isValid = false;
    }

    return isValid;
  }

  function handleSecondValidation() {
    var isValid = true;

    var errorFirstLine = "במדד ״חשיבות לשירות״ בוצעה טעות:";
    var errorSecondLine = '1. בכל ציון הערך של "עד-" חייב להיות גדול מהערך של "מ-".​';
    var errorThirdLine = '2. הערך של "מ-" של ציון 5 חייב להיות 0.​';
    var errorFourthLine = '3. הערך של "עד-" של ציון מסוים חייב להיות שווה ל-"מ-" של הציון הקודם לו.​';
    var errorFifthLine = "המודל אינו נשמר, יש לתקנו ולשמור אותו מחדש.";

    const importanceServiceOneFrom = Number(inputValueImportanceServiceOneFrom);
    const importanceServiceOneTo = Number(inputValueImportanceServiceOneTo);
    const importanceServiceTwoFrom = Number(inputValueImportanceServiceTwoFrom);
    const importanceServiceTwoTo = Number(inputValueImportanceServiceTwoTo);
    const importanceServiceThreeFrom = Number(inputValueImportanceServiceThreeFrom);
    const importanceServiceThreeTo = Number(inputValueImportanceServiceThreeTo);
    const importanceServiceFourFrom = Number(inputValueImportanceServiceFourFrom);
    const importanceServiceFourTo = Number(inputValueImportanceServiceFourTo);
    const importanceServiceFiveFrom = Number(inputValueImportanceServiceFiveFrom);
    const importanceServiceFiveTo = Number(inputValueImportanceServiceFiveTo);

    var firstCondition = importanceServiceFiveFrom === 0;
    var secondCondition =
      importanceServiceFiveTo === importanceServiceFourFrom &&
      importanceServiceFourTo === importanceServiceThreeFrom &&
      importanceServiceThreeTo === importanceServiceTwoFrom &&
      importanceServiceTwoTo === importanceServiceOneFrom;
    var thirdCondition =
      importanceServiceOneTo > importanceServiceOneFrom &&
      importanceServiceTwoTo > importanceServiceTwoFrom &&
      importanceServiceThreeTo > importanceServiceThreeFrom &&
      importanceServiceFourTo > importanceServiceFourFrom &&
      importanceServiceFiveTo > importanceServiceFiveFrom;

    if (!firstCondition || !secondCondition || !thirdCondition) {
      alert(errorFirstLine + "\n" + errorSecondLine + "\n" + errorThirdLine + "\n" + errorFourthLine + "\n" + errorFifthLine);

      isValid = false;
    }

    return isValid;
  }

  function handleThirdValidation() {
    var isValid = true;

    var errorFirstLine = "במדד ״פוטנציאל״ בוצעה טעות:";
    var errorSecondLine = '1. בכל ציון הערך של "עד-" חייב להיות גדול מהערך של "מ-".​';
    var errorThirdLine = '2. הערך של "מ-" של ציון 1 חייב להיות 0.​';
    var errorFourthLine = '3. הערך של "עד-" של ציון מסוים חייב להיות שווה ל-"מ-" של הציון שלאחריו.​';
    var errorFifthLine = "המודל אינו נשמר, יש לתקנו ולשמור אותו מחדש.";

    const potentialOneFrom = Number(inputValuePotentialOneFrom);
    const potentialOneTo = Number(inputValuePotentialOneTo);
    const potentialTwoFrom = Number(inputValuePotentialTwoFrom);
    const potentialTwoTo = Number(inputValuePotentialTwoTo);
    const potentialThreeFrom = Number(inputValuePotentialThreeFrom);
    const potentialThreeTo = Number(inputValuePotentialThreeTo);
    const potentialFourFrom = Number(inputValuePotentialFourFrom);
    const potentialFourTo = Number(inputValuePotentialFourTo);
    const potentialFiveFrom = Number(inputValuePotentialFiveFrom);
    const potentialFiveTo = Number(inputValuePotentialFiveTo);

    var firstCondition = potentialOneFrom === 0;
    var secondCondition =
      potentialOneTo === potentialTwoFrom && potentialTwoTo === potentialThreeFrom && potentialThreeTo === potentialFourFrom && potentialFourTo === potentialFiveFrom;
    var thirdCondition =
      potentialOneTo > potentialOneFrom &&
      potentialTwoTo > potentialTwoFrom &&
      potentialThreeTo > potentialThreeFrom &&
      potentialFourTo > potentialFourFrom &&
      potentialFiveTo > potentialFiveFrom;

    if (!firstCondition || !secondCondition || !thirdCondition) {
      alert(errorFirstLine + "\n" + errorSecondLine + "\n" + errorThirdLine + "\n" + errorFourthLine + "\n" + errorFifthLine);

      isValid = false;
    }

    return isValid;
  }

  function handleFourthValidation() {
    var isValid = true;

    var errorFirstLine = "במדד ״צמצום פער תפעולי״ בוצעה טעות:";
    var errorSecondLine = '1. בכל ציון הערך של "עד-" חייב להיות גדול מהערך של "מ-".​​';
    var errorThirdLine = '2. הערך של "מ-" של ציון 1 חייב להיות 0.​';
    var errorFourthLine = '3. הערך של "עד-" של ציון מסוים חייב להיות שווה ל-"מ-" של הציון שלאחריו.​';
    var errorFifthLine = "המודל אינו נשמר, יש לתקנו ולשמור אותו מחדש.";

    const reducingOperationalGapOneFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapOneFrom);
    const reducingOperationalGapOneTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapOneTo);
    const reducingOperationalGapTwoFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapTwoFrom);
    const reducingOperationalGapTwoTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapTwoTo);
    const reducingOperationalGapThreeFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapThreeFrom);
    const reducingOperationalGapThreeTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapThreeTo);
    const reducingOperationalGapFourFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapFourFrom);
    const reducingOperationalGapFourTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapFourTo);
    const reducingOperationalGapFiveFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapFiveFrom);
    const reducingOperationalGapFiveTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueReducingOperationalGapFiveTo);

    var firstCondition = reducingOperationalGapOneFrom === 0;
    var secondCondition =
      reducingOperationalGapOneTo === reducingOperationalGapTwoFrom &&
      reducingOperationalGapTwoTo === reducingOperationalGapThreeFrom &&
      reducingOperationalGapThreeTo === reducingOperationalGapFourFrom &&
      reducingOperationalGapFourTo === reducingOperationalGapFiveFrom;
    var thirdCondition =
      reducingOperationalGapOneTo > reducingOperationalGapOneFrom &&
      reducingOperationalGapTwoTo > reducingOperationalGapTwoFrom &&
      reducingOperationalGapThreeTo > reducingOperationalGapThreeFrom &&
      reducingOperationalGapFourTo > reducingOperationalGapFourFrom &&
      reducingOperationalGapFiveTo > reducingOperationalGapFiveFrom;

    if (!firstCondition || !secondCondition || !thirdCondition) {
      alert(errorFirstLine + "\n" + errorSecondLine + "\n" + errorThirdLine + "\n" + errorFourthLine + "\n" + errorFifthLine);

      isValid = false;
    }

    return isValid;
  }

  function handleFifthValidation() {
    var isValid = true;

    var errorFirstLine = "במדד ״עלות לנוסע״ בוצעה טעות:";
    var errorSecondLine = '1. בכל ציון הערך של "עד-" חייב להיות גדול מהערך של "מ-".​';
    var errorThirdLine = '2. הערך של "מ-" של ציון 5 חייב להיות 0.​';
    var errorFourthLine = '3. הערך של "עד-" של ציון מסוים חייב להיות שווה ל-"מ-" של הציון הקודם לו.​';
    var errorFifthLine = "המודל אינו נשמר, יש לתקנו ולשמור אותו מחדש.";

    const costPerPassengerOneFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerOneFrom);
    const costPerPassengerOneTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerOneTo);
    const costPerPassengerTwoFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerTwoFrom);
    const costPerPassengerTwoTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerTwoTo);
    const costPerPassengerThreeFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerThreeFrom);
    const costPerPassengerThreeTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerThreeTo);
    const costPerPassengerFourFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerFourFrom);
    const costPerPassengerFourTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerFourTo);
    const costPerPassengerFiveFrom = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerFiveFrom);
    const costPerPassengerFiveTo = utilsUnit.toNumberWithoutTrailingZeros(inputValueCostPerPassengerFiveTo);

    var firstCondition = costPerPassengerFiveFrom === 0;
    var secondCondition =
      costPerPassengerFiveTo === costPerPassengerFourFrom &&
      costPerPassengerFourTo === costPerPassengerThreeFrom &&
      costPerPassengerThreeTo === costPerPassengerTwoFrom &&
      costPerPassengerTwoTo === costPerPassengerOneFrom;
    var thirdCondition =
      costPerPassengerOneTo > costPerPassengerOneFrom &&
      costPerPassengerTwoTo > costPerPassengerTwoFrom &&
      costPerPassengerThreeTo > costPerPassengerThreeFrom &&
      costPerPassengerFourTo > costPerPassengerFourFrom &&
      costPerPassengerFiveTo > costPerPassengerFiveFrom;

    if (!firstCondition || !secondCondition || !thirdCondition) {
      alert(errorFirstLine + "\n" + errorSecondLine + "\n" + errorThirdLine + "\n" + errorFourthLine + "\n" + errorFifthLine);

      isValid = false;
    }

    return isValid;
  }

  function handleSixthValidation() {
    var isValid = true;

    var errorFirstLine = "במדד ״עלות לנסיעה״ בוצעה טעות:";
    var errorSecondLine = '1. בכל ציון הערך של "עד-" חייב להיות גדול מהערך של "מ-".​';
    var errorThirdLine = '2. הערך של "מ-" של ציון 5 חייב להיות 0.​';
    var errorFourthLine = '3. הערך של "עד-" של ציון מסוים חייב להיות שווה ל-"מ-" של הציון הקודם לו.​';
    var errorFifthLine = "המודל אינו נשמר, יש לתקנו ולשמור אותו מחדש.";

    const costPerTripOneFrom = Number(inputValueCostPerTripOneFrom);
    const costPerTripOneTo = Number(inputValueCostPerTripOneTo);
    const costPerTripTwoFrom = Number(inputValueCostPerTripTwoFrom);
    const costPerTripTwoTo = Number(inputValueCostPerTripTwoTo);
    const costPerTripThreeFrom = Number(inputValueCostPerTripThreeFrom);
    const costPerTripThreeTo = Number(inputValueCostPerTripThreeTo);
    const costPerTripFourFrom = Number(inputValueCostPerTripFourFrom);
    const costPerTripFourTo = Number(inputValueCostPerTripFourTo);
    const costPerTripFiveFrom = Number(inputValueCostPerTripFiveFrom);
    const costPerTripFiveTo = Number(inputValueCostPerTripFiveTo);

    var firstCondition = costPerTripFiveFrom === 0;
    var secondCondition =
      costPerTripFiveTo === costPerTripFourFrom &&
      costPerTripFourTo === costPerTripThreeFrom &&
      costPerTripThreeTo === costPerTripTwoFrom &&
      costPerTripTwoTo === costPerTripOneFrom;
    var thirdCondition =
      costPerTripOneTo > costPerTripOneFrom &&
      costPerTripTwoTo > costPerTripTwoFrom &&
      costPerTripThreeTo > costPerTripThreeFrom &&
      costPerTripFourTo > costPerTripFourFrom &&
      costPerTripFiveTo > costPerTripFiveFrom;

    if (!firstCondition || !secondCondition || !thirdCondition) {
      alert(errorFirstLine + "\n" + errorSecondLine + "\n" + errorThirdLine + "\n" + errorFourthLine + "\n" + errorFifthLine);

      isValid = false;
    }

    return isValid;
  }

  // handle - logic
  const handleCancelModelChanges = () => {
    if (utilsUnit.areJSONsEqualLoose(jsonEditRefCheckChanges.current, jsonEditRef.current)) {
      navigate(-1);
    } else {
      showEditAlertDialog();
    }
  };

  const showEditAlertDialog = () => {
    setDialogEditOpen(true);
  };

  const closeEditAlertDialog = (cancelEdit) => {
    setDialogEditOpen(false);

    if (cancelEdit) {
      navigate(-1);
    }
  };

  const showSaveAlertDialog = () => {
    setDialogSaveOpen(true);
  };

  const closeSaveAlertDialog = () => {
    setDialogSaveOpen(false);
    navigate(-1);
  };

  const handleChangeRegexNComma = (event, regex, setInputValue, jsonKey) => {
    const value = event.target.value;

    // Remove commas from the input value
    const valueWithoutComma = utilsUnit.removeCommas(value);

    // Validate the cleaned value
    if (regex.test(valueWithoutComma)) {
      // Update state with the cleaned value
      setInputValue(valueWithoutComma);

      // Update the corresponding JSON field
      jsonEditRef.current[jsonKey] = valueWithoutComma;
    }
  };

  const handleChangeRegex = (event, regex, setInputValue, jsonKey) => {
    const value = event.target.value;

    if (regex.test(value)) {
      setInputValue(value);

      jsonEditRef.current[jsonKey] = value;
    }
  };

  const handleChangeRegexPercent = (event, regex, setInputValue, jsonKey) => {
    let value = event.target.value;

    value = value.replace(/^0+(?!$)/, "");

    if (regex.test(value) && /^\d{0,3}$/.test(value) && value <= 100) {
      setInputValue(value);

      jsonEditRef.current[jsonKey] = value;
    }
  };

  const handleChangeSimple = (event, setInputValue, jsonKey) => {
    const value = event.target.value;

    setInputValue(value);

    jsonEditRef.current[jsonKey] = value;
  };

  // handleChange general
  const handleChangeLifeTime = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueLifeTime, "life_time");
  };

  const handleChangeBusinessDays = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueBusinessDays, "business_days");
  };

  const handleChangeMetropolitanRingOne = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMetropolitanRingOne, "metropolitan_ring_one");
  };

  const handleChangeMetropolitanRingTwo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMetropolitanRingTwo, "metropolitan_ring_two");
  };

  const handleChangeMetropolitanRingThree = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMetropolitanRingThree, "metropolitan_ring_three");
  };

  const handleChangeMetropolitanRingFour = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMetropolitanRingFour, "metropolitan_ring_four");
  };

  const handleChangeCostPerAcre = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostPerAcre, "cost_per_acre");
  };

  // handleChange Percent
  const handleChangeUrgencyPercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValueUrgencyPercent, "urgency_percent");
  };

  const handleChangeImportanceServicePercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValueImportanceServicePercent, "importance_service_percent");
  };

  const handleChangePotentialPercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValuePotentialPercent, "potential_percent");
  };

  const handleChangeReducingOperationalGapPercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValueReducingOperationalGapPercent, "reducing_operational_gap_percent");
  };

  const handleChangeCostPerPassengerPercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValueCostPerPassengerPercent, "cost_per_passenger_percent");
  };

  const handleChangeCostPerTripPercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValueCostPerTripPercent, "cost_per_trip_percent");
  };

  const handleChangePositionLocalAuthorityPercent = (event) => {
    handleChangeRegexPercent(event, utilsUnit.regexInt, setInputValuePositionLocalAuthorityPercent, "position_local_authority_percent");
  };

  // handleChange One
  const handleChangeUrgencyOne = (event) => {
    handleChangeSimple(event, setInputValueUrgencyOne, "urgency_one");
  };

  const handleChangeImportanceServiceOneFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceOneFrom, "importance_service_one_from");
  };

  const handleChangeImportanceServiceOneTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceOneTo, "importance_service_one_to");
  };

  const handleChangePotentialOneFrom = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialOneFrom, "potential_one_from");
  };

  const handleChangePotentialOneTo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialOneTo, "potential_one_to");
  };

  const handleChangeReducingOperationalGapOneFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapOneFrom, "reducing_operational_gap_one_from");
  };

  const handleChangeReducingOperationalGapOneTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapOneTo, "reducing_operational_gap_one_to");
  };

  const handleChangeCostPerPassengerOneFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerOneFrom, "cost_per_passenger_one_from");
  };

  const handleChangeCostPerPassengerOneTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerOneTo, "cost_per_passenger_one_to");
  };

  const handleChangeCostPerTripOneFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripOneFrom, "cost_per_trip_one_from");
  };

  const handleChangeCostPerTripOneTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripOneTo, "cost_per_trip_one_to");
  };

  // handleChange Two
  const handleChangeUrgencyTwo = (event) => {
    handleChangeSimple(event, setInputValueUrgencyTwo, "urgency_two");
  };

  const handleChangeImportanceServiceTwoFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceTwoFrom, "importance_service_two_from");
  };

  const handleChangeImportanceServiceTwoTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceTwoTo, "importance_service_two_to");
  };

  const handleChangePotentialTwoFrom = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialTwoFrom, "potential_two_from");
  };

  const handleChangePotentialTwoTo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialTwoTo, "potential_two_to");
  };

  const handleChangeReducingOperationalGapTwoFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapTwoFrom, "reducing_operational_gap_two_from");
  };

  const handleChangeReducingOperationalGapTwoTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapTwoTo, "reducing_operational_gap_two_to");
  };

  const handleChangeCostPerPassengerTwoFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerTwoFrom, "cost_per_passenger_two_from");
  };

  const handleChangeCostPerPassengerTwoTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerTwoTo, "cost_per_passenger_two_to");
  };

  const handleChangeCostPerTripTwoFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripTwoFrom, "cost_per_trip_two_from");
  };

  const handleChangeCostPerTripTwoTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripTwoTo, "cost_per_trip_two_to");
  };

  // handleChange Three
  const handleChangeUrgencyThree = (event) => {
    handleChangeSimple(event, setInputValueUrgencyThree, "urgency_three");
  };

  const handleChangeImportanceServiceThreeFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceThreeFrom, "importance_service_three_from");
  };

  const handleChangeImportanceServiceThreeTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceThreeTo, "importance_service_three_to");
  };

  const handleChangePotentialThreeFrom = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialThreeFrom, "potential_three_from");
  };

  const handleChangePotentialThreeTo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialThreeTo, "potential_three_to");
  };

  const handleChangeReducingOperationalGapThreeFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapThreeFrom, "reducing_operational_gap_three_from");
  };

  const handleChangeReducingOperationalGapThreeTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapThreeTo, "reducing_operational_gap_three_to");
  };

  const handleChangeCostPerPassengerThreeFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerThreeFrom, "cost_per_passenger_three_from");
  };

  const handleChangeCostPerPassengerThreeTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerThreeTo, "cost_per_passenger_three_to");
  };

  const handleChangeCostPerTripThreeFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripThreeFrom, "cost_per_trip_three_from");
  };

  const handleChangeCostPerTripThreeTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripThreeTo, "cost_per_trip_three_to");
  };

  // handleChange Four
  const handleChangeUrgencyFour = (event) => {
    handleChangeSimple(event, setInputValueUrgencyFour, "urgency_four");
  };

  const handleChangeImportanceServiceFourFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceFourFrom, "importance_service_four_from");
  };

  const handleChangeImportanceServiceFourTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceFourTo, "importance_service_four_to");
  };

  const handleChangePotentialFourFrom = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialFourFrom, "potential_four_from");
  };

  const handleChangePotentialFourTo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialFourTo, "potential_four_to");
  };

  const handleChangeReducingOperationalGapFourFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapFourFrom, "reducing_operational_gap_four_from");
  };

  const handleChangeReducingOperationalGapFourTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapFourTo, "reducing_operational_gap_four_to");
  };

  const handleChangeCostPerPassengerFourFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerFourFrom, "cost_per_passenger_four_from");
  };

  const handleChangeCostPerPassengerFourTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerFourTo, "cost_per_passenger_four_to");
  };

  const handleChangeCostPerTripFourFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripFourFrom, "cost_per_trip_four_from");
  };

  const handleChangeCostPerTripFourTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripFourTo, "cost_per_trip_four_to");
  };

  // handleChange Five
  const handleChangeUrgencyFive = (event) => {
    handleChangeSimple(event, setInputValueUrgencyFive, "urgency_five");
  };

  const handleChangeImportanceServiceFiveFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceFiveFrom, "importance_service_five_from");
  };

  const handleChangeImportanceServiceFiveTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueImportanceServiceFiveTo, "importance_service_five_to");
  };

  const handleChangePotentialFiveFrom = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialFiveFrom, "potential_five_from");
  };

  const handleChangePotentialFiveTo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValuePotentialFiveTo, "potential_five_to");
  };

  const handleChangeReducingOperationalGapFiveFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapFiveFrom, "reducing_operational_gap_five_from");
  };

  const handleChangeReducingOperationalGapFiveTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueReducingOperationalGapFiveTo, "reducing_operational_gap_five_to");
  };

  const handleChangeCostPerPassengerFiveFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerFiveFrom, "cost_per_passenger_five_from");
  };

  const handleChangeCostPerPassengerFiveTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexDecimalTwoNumber, setInputValueCostPerPassengerFiveTo, "cost_per_passenger_five_to");
  };

  const handleChangeCostPerTripFiveFrom = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripFiveFrom, "cost_per_trip_five_from");
  };

  const handleChangeCostPerTripFiveTo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueCostPerTripFiveTo, "cost_per_trip_five_to");
  };

  // handleChange Costs
  // handleChange Costs One
  const handleChangeMonthsOfWorkOne = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMonthsOfWorkOne, "months_of_work_one");
  };

  const handleChangeCostOfBuildingsOne = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfBuildingsOne, "cost_of_buildings_one");
  };

  const handleChangeCostOfTrafficOne = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfTrafficOne, "cost_of_traffic_one");
  };

  // handleChange Costs Two
  const handleChangeMonthsOfWorkTwo = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMonthsOfWorkTwo, "months_of_work_two");
  };

  const handleChangeCostOfBuildingsTwo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfBuildingsTwo, "cost_of_buildings_two");
  };

  const handleChangeCostOfTrafficTwo = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfTrafficTwo, "cost_of_traffic_two");
  };

  // handleChange Costs Three
  const handleChangeMonthsOfWorkThree = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMonthsOfWorkThree, "months_of_work_three");
  };

  const handleChangeCostOfBuildingsThree = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfBuildingsThree, "cost_of_buildings_three");
  };

  const handleChangeCostOfTrafficThree = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfTrafficThree, "cost_of_traffic_three");
  };

  // handleChange Costs Four
  const handleChangeMonthsOfWorkFour = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMonthsOfWorkFour, "months_of_work_four");
  };

  const handleChangeCostOfBuildingsFour = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfBuildingsFour, "cost_of_buildings_four");
  };

  const handleChangeCostOfTrafficFour = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfTrafficFour, "cost_of_traffic_four");
  };

  // handleChange Costs Five
  const handleChangeMonthsOfWorkFive = (event) => {
    handleChangeRegex(event, utilsUnit.regexInt, setInputValueMonthsOfWorkFive, "months_of_work_five");
  };

  const handleChangeCostOfBuildingsFive = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfBuildingsFive, "cost_of_buildings_five");
  };

  const handleChangeCostOfTrafficFive = (event) => {
    handleChangeRegexNComma(event, utilsUnit.regexInt, setInputValueCostOfTrafficFive, "cost_of_traffic_five");
  };

  // Return JSX
  if (!dataIsReceived) {
    return <div />;
  }

  return (
    <div className="div-main-2-3">
      <DivRow style={{ width: "1920px", height: "100vh" }}>
        {right_design_ui()}
        <WidgetMarginWidth width="7px" />
        <DivColumn className="model-2-frame">{model_design_general()}</DivColumn>
      </DivRow>
      {alert_dialogs()}
    </div>
  );

  function right_design_ui() {
    return (
      <DivColumn>
        <DivColumn className="model-1-frame" style={{ display: "inline-flex" }}>
          <ReactSVG src={ConstantsImages.TRANSPORTATION_SMALL_IMAGE} />
          <ParagraphText className="model-text-shared-1">מודל לתיעדוף מתקני תשתית לתפעול תח״צ באוטובוסים</ParagraphText>
        </DivColumn>
        <WidgetMarginHeight height="16px" />
        <ParagraphText className="model-text-1">הגדרות מודל</ParagraphText>
      </DivColumn>
    );
  }

  function model_design_general() {
    return (
      <DivColumn className="model-2-frame">
        <div className="model-2-frame-2">
          <DivColumn className="model-2-frame-3">
            {model_design_1()}
            {model_design_2()}
          </DivColumn>
          {model_design_3()}
        </div>
        {model_design_4()}
      </DivColumn>
    );
  }

  function model_design_1() {
    return (
      <DivColumn className="model-2-frame-4">
        <div className="model-2-frame-5">
          {model_design_1_1()}
          {model_design_1_2()}
          {model_design_1_3()}
          {model_design_1_4()}
          {model_design_1_5()}
          {model_design_1_6()}
          {model_design_1_7()}
          {model_design_1_8()}
        </div>
      </DivColumn>
    );
  }

  function model_design_1_1() {
    return (
      <DivColumn className="model-2-frame-6">
        <DivColumn className="model-2-frame-7">
          <ReactSVG src={ConstantsImages.CAR_IMAGE} />
          <ParagraphText className="model-text-2" style={{ textAlign: "center" }}>
            יעילות תחבורתית
          </ParagraphText>
        </DivColumn>
        <WidgetProgressPercent progress={getMainMeasurePercent(1)} />
      </DivColumn>
    );
  }

  function model_design_1_2() {
    return (
      <DivColumn className="model-2-frame-9">
        <DivColumn className="model-2-frame-10">
          <WidgetInputPercent value={inputValueUrgencyPercent} onChange={handleChangeUrgencyPercent} />
        </DivColumn>
        <WidgetInputPercent value={inputValueImportanceServicePercent} onChange={handleChangeImportanceServicePercent} />
        <WidgetInputPercent value={inputValuePotentialPercent} onChange={handleChangePotentialPercent} />
        <WidgetInputPercent value={inputValueReducingOperationalGapPercent} onChange={handleChangeReducingOperationalGapPercent} />
      </DivColumn>
    );
  }

  function model_design_1_3() {
    return (
      <DivColumn className="model-2-frame-14" style={{ width: "125px" }}>
        <DivColumn className="model-2-frame-15">
          <ModelDesignFrameText title="דחיפות" text="שנת יעד" />
        </DivColumn>
        <ModelDesignFrameText title="חשיבות לשירות" text="כמות מסופים במרחב" />
        <ModelDesignFrameText title="פוטנציאל" text="כמות אוכלוסייה" />
        <ModelDesignFrameText title="צמצום פער תפעולי" text="יחס" />
      </DivColumn>
    );
  }

  function model_design_1_4() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={1} />
          <WidgetInputSecond value={inputValueUrgencyOne} onChange={handleChangeUrgencyOne} widthProp="183.4px" textAlignProp="right" />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueImportanceServiceOneFrom}
          valueToInput={inputValueImportanceServiceOneTo}
          onChangeFromInput={handleChangeImportanceServiceOneFrom}
          onChangeToInput={handleChangeImportanceServiceOneTo}
        />
        <WidgetInputsFromTo
          valueFromInput={utilsUnit.formatNumberWithCommas(inputValuePotentialOneFrom)}
          valueToInput={utilsUnit.formatNumberWithCommas(inputValuePotentialOneTo)}
          onChangeFromInput={handleChangePotentialOneFrom}
          onChangeToInput={handleChangePotentialOneTo}
        />
        <WidgetInputsFromTo
          valueFromInput={inputValueReducingOperationalGapOneFrom}
          valueToInput={inputValueReducingOperationalGapOneTo}
          onChangeFromInput={handleChangeReducingOperationalGapOneFrom}
          onChangeToInput={handleChangeReducingOperationalGapOneTo}
        />
      </DivColumn>
    );
  }

  function model_design_1_5() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={2} />
          <WidgetInputSecond value={inputValueUrgencyTwo} onChange={handleChangeUrgencyTwo} widthProp="183.4px" textAlignProp="right" />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueImportanceServiceTwoFrom}
          valueToInput={inputValueImportanceServiceTwoTo}
          onChangeFromInput={handleChangeImportanceServiceTwoFrom}
          onChangeToInput={handleChangeImportanceServiceTwoTo}
        />
        <WidgetInputsFromTo
          valueFromInput={utilsUnit.formatNumberWithCommas(inputValuePotentialTwoFrom)}
          valueToInput={utilsUnit.formatNumberWithCommas(inputValuePotentialTwoTo)}
          onChangeFromInput={handleChangePotentialTwoFrom}
          onChangeToInput={handleChangePotentialTwoTo}
        />
        <WidgetInputsFromTo
          valueFromInput={inputValueReducingOperationalGapTwoFrom}
          valueToInput={inputValueReducingOperationalGapTwoTo}
          onChangeFromInput={handleChangeReducingOperationalGapTwoFrom}
          onChangeToInput={handleChangeReducingOperationalGapTwoTo}
        />
      </DivColumn>
    );
  }

  function model_design_1_6() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={3} />
          <WidgetInputSecond value={inputValueUrgencyThree} onChange={handleChangeUrgencyThree} widthProp="183.4px" textAlignProp="right" />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueImportanceServiceThreeFrom}
          valueToInput={inputValueImportanceServiceThreeTo}
          onChangeFromInput={handleChangeImportanceServiceThreeFrom}
          onChangeToInput={handleChangeImportanceServiceThreeTo}
        />
        <WidgetInputsFromTo
          valueFromInput={utilsUnit.formatNumberWithCommas(inputValuePotentialThreeFrom)}
          valueToInput={utilsUnit.formatNumberWithCommas(inputValuePotentialThreeTo)}
          onChangeFromInput={handleChangePotentialThreeFrom}
          onChangeToInput={handleChangePotentialThreeTo}
        />
        <WidgetInputsFromTo
          valueFromInput={inputValueReducingOperationalGapThreeFrom}
          valueToInput={inputValueReducingOperationalGapThreeTo}
          onChangeFromInput={handleChangeReducingOperationalGapThreeFrom}
          onChangeToInput={handleChangeReducingOperationalGapThreeTo}
        />
      </DivColumn>
    );
  }

  function model_design_1_7() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={4} />
          <WidgetInputSecond value={inputValueUrgencyFour} onChange={handleChangeUrgencyFour} widthProp="183.4px" textAlignProp="right" />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueImportanceServiceFourFrom}
          valueToInput={inputValueImportanceServiceFourTo}
          onChangeFromInput={handleChangeImportanceServiceFourFrom}
          onChangeToInput={handleChangeImportanceServiceFourTo}
        />
        <WidgetInputsFromTo
          valueFromInput={utilsUnit.formatNumberWithCommas(inputValuePotentialFourFrom)}
          valueToInput={utilsUnit.formatNumberWithCommas(inputValuePotentialFourTo)}
          onChangeFromInput={handleChangePotentialFourFrom}
          onChangeToInput={handleChangePotentialFourTo}
        />
        <WidgetInputsFromTo
          valueFromInput={inputValueReducingOperationalGapFourFrom}
          valueToInput={inputValueReducingOperationalGapFourTo}
          onChangeFromInput={handleChangeReducingOperationalGapFourFrom}
          onChangeToInput={handleChangeReducingOperationalGapFourTo}
        />
      </DivColumn>
    );
  }

  function model_design_1_8() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={5} />
          <WidgetInputSecond value={inputValueUrgencyFive} onChange={handleChangeUrgencyFive} widthProp="183.4px" textAlignProp="right" />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueImportanceServiceFiveFrom}
          valueToInput={inputValueImportanceServiceFiveTo}
          onChangeFromInput={handleChangeImportanceServiceFiveFrom}
          onChangeToInput={handleChangeImportanceServiceFiveTo}
        />
        <WidgetInputsFromTo
          valueFromInput={utilsUnit.formatNumberWithCommas(inputValuePotentialFiveFrom)}
          valueToInput={utilsUnit.formatNumberWithCommas(inputValuePotentialFiveTo)}
          onChangeFromInput={handleChangePotentialFiveFrom}
          onChangeToInput={handleChangePotentialFiveTo}
        />
        <WidgetInputsFromTo
          valueFromInput={inputValueReducingOperationalGapFiveFrom}
          valueToInput={inputValueReducingOperationalGapFiveTo}
          onChangeFromInput={handleChangeReducingOperationalGapFiveFrom}
          onChangeToInput={handleChangeReducingOperationalGapFiveTo}
        />
      </DivColumn>
    );
  }

  function model_design_2() {
    return (
      <DivColumn className="model-2-frame-4" style={{ height: "233px" }}>
        <div className="model-2-frame-5">
          {model_design_2_1()}
          {model_design_2_2()}
          {model_design_2_3()}
          {model_design_2_4()}
          {model_design_2_5()}
          {model_design_2_6()}
          {model_design_2_7()}
          {model_design_2_8()}
        </div>
      </DivColumn>
    );
  }

  function model_design_2_1() {
    return (
      <DivColumn className="model-2-frame-6" style={{ height: "183px", gap: "0px" }}>
        <DivColumn className="model-2-frame-7">
          <ReactSVG src={ConstantsImages.COINS_IMAGE} />
          <ParagraphText className="model-text-2" style={{ textAlign: "center" }}>
            יעילות כלכלית
          </ParagraphText>
        </DivColumn>
        <WidgetMarginHeight height="9px" />
        <WidgetProgressPercent progress={getMainMeasurePercent(2)} />
      </DivColumn>
    );
  }

  function model_design_2_2() {
    return (
      <DivColumn className="model-2-frame-9">
        <DivColumn className="model-2-frame-10">
          <WidgetInputPercent value={inputValueCostPerPassengerPercent} onChange={handleChangeCostPerPassengerPercent} />
        </DivColumn>
        <WidgetInputPercent value={inputValueCostPerTripPercent} onChange={handleChangeCostPerTripPercent} />
      </DivColumn>
    );
  }

  function model_design_2_3() {
    return (
      <DivColumn className="model-2-frame-14" style={{ width: "125px" }}>
        <DivColumn className="model-2-frame-15">
          <ModelDesignFrameText title="עלות לנוסע" text="" />
        </DivColumn>
        <ModelDesignFrameText title="עלות לנסיעה" text="" />
      </DivColumn>
    );
  }

  function model_design_2_4() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={1} />
          <WidgetInputsFromTo
            valueFromInput={inputValueCostPerPassengerOneFrom}
            valueToInput={inputValueCostPerPassengerOneTo}
            onChangeFromInput={handleChangeCostPerPassengerOneFrom}
            onChangeToInput={handleChangeCostPerPassengerOneTo}
          />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueCostPerTripOneFrom}
          valueToInput={inputValueCostPerTripOneTo}
          onChangeFromInput={handleChangeCostPerTripOneFrom}
          onChangeToInput={handleChangeCostPerTripOneTo}
        />
      </DivColumn>
    );
  }

  function model_design_2_5() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={2} />
          <WidgetInputsFromTo
            valueFromInput={inputValueCostPerPassengerTwoFrom}
            valueToInput={inputValueCostPerPassengerTwoTo}
            onChangeFromInput={handleChangeCostPerPassengerTwoFrom}
            onChangeToInput={handleChangeCostPerPassengerTwoTo}
          />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueCostPerTripTwoFrom}
          valueToInput={inputValueCostPerTripTwoTo}
          onChangeFromInput={handleChangeCostPerTripTwoFrom}
          onChangeToInput={handleChangeCostPerTripTwoTo}
        />
      </DivColumn>
    );
  }

  function model_design_2_6() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={3} />
          <WidgetInputsFromTo
            valueFromInput={inputValueCostPerPassengerThreeFrom}
            valueToInput={inputValueCostPerPassengerThreeTo}
            onChangeFromInput={handleChangeCostPerPassengerThreeFrom}
            onChangeToInput={handleChangeCostPerPassengerThreeTo}
          />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueCostPerTripThreeFrom}
          valueToInput={inputValueCostPerTripThreeTo}
          onChangeFromInput={handleChangeCostPerTripThreeFrom}
          onChangeToInput={handleChangeCostPerTripThreeTo}
        />
      </DivColumn>
    );
  }

  function model_design_2_7() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={4} />
          <WidgetInputsFromTo
            valueFromInput={inputValueCostPerPassengerFourFrom}
            valueToInput={inputValueCostPerPassengerFourTo}
            onChangeFromInput={handleChangeCostPerPassengerFourFrom}
            onChangeToInput={handleChangeCostPerPassengerFourTo}
          />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueCostPerTripFourFrom}
          valueToInput={inputValueCostPerTripFourTo}
          onChangeFromInput={handleChangeCostPerTripFourFrom}
          onChangeToInput={handleChangeCostPerTripFourTo}
        />
      </DivColumn>
    );
  }

  function model_design_2_8() {
    return (
      <DivColumn className="model-2-frame-17">
        <DivColumn className="model-2-frame-18">
          <GaugeUI numberText={5} />
          <WidgetInputsFromTo
            valueFromInput={inputValueCostPerPassengerFiveFrom}
            valueToInput={inputValueCostPerPassengerFiveTo}
            onChangeFromInput={handleChangeCostPerPassengerFiveFrom}
            onChangeToInput={handleChangeCostPerPassengerFiveTo}
          />
        </DivColumn>
        <WidgetInputsFromTo
          valueFromInput={inputValueCostPerTripFiveFrom}
          valueToInput={inputValueCostPerTripFiveTo}
          onChangeFromInput={handleChangeCostPerTripFiveFrom}
          onChangeToInput={handleChangeCostPerTripFiveTo}
        />
      </DivColumn>
    );
  }

  function model_design_3() {
    return (
      <DivColumn className="model-2-frame-27">
        <div className="model-2-frame-28">
          <DivColumn className="terminal-5-frame-11" style={{ width: "190px" }} onClick={handleSaveModelChanges}>
            <ParagraphText className="terminal-text-31">שמירה וחישוב מחדש</ParagraphText>
          </DivColumn>
          <DivColumn className="model-2-frame-29" onClick={handleCancelModelChanges}>
            <ParagraphText className="terminal-text-31" style={{ color: "#46CDB3" }}>
              ביטול
            </ParagraphText>
          </DivColumn>
        </div>
        <DivColumn className="model-2-frame-30">
          <WidgetInput
            label="זמן חיים"
            value={inputValueLifeTime}
            onChange={handleChangeLifeTime}
            heightProp="50px"
            backgoundColortProp="#D4D7E5"
            paddingProp="0px 30px"
            fontSizeTitle="14px"
          />
          <WidgetInput
            label="ימי עבודה"
            value={inputValueBusinessDays}
            onChange={handleChangeBusinessDays}
            heightProp="50px"
            backgoundColortProp="#D4D7E5"
            paddingProp="0px 30px"
            fontSizeTitle="14px"
          />
        </DivColumn>
        <div className="model-2-frame-31" style={{ height: "170px" }}>
          <DivColumn className="model-2-frame-32">
            <WidgetProgressPercent progress={getMainMeasurePercent(3)} />
            <WidgetInputPercent
              value={inputValuePositionLocalAuthorityPercent}
              onChange={handleChangePositionLocalAuthorityPercent}
              heightProp="42px"
              widthProp="105.5px"
              paddingRightProp="105.5px"
              rightPropText="34.5px"
            />
          </DivColumn>
          <DivColumn className="model-2-frame-33">
            <DivColumn className="model-2-frame-34" style={{ height: "90px" }}>
              <ReactSVG src={ConstantsImages.COINS_IMAGE} />
              <ParagraphText className="model-text-2">ישימות</ParagraphText>
            </DivColumn>
            <ParagraphText className="model-text-8">עמדת הרשות המקומית</ParagraphText>
          </DivColumn>
        </div>
        <DivColumn className="model-2-frame-35">
          <ParagraphText className="model-text-9">טבעת מטרופולינית</ParagraphText>
          <DivColumn className="model-2-frame-36">
            <div className="model-2-frame-37">
              <WidgetInput
                label="ראשונה"
                value={inputValueMetropolitanRingOne}
                onChange={handleChangeMetropolitanRingOne}
                widthProp="117px"
                fontSizeTitle="14px"
                paddingProp="0px 20px"
              />
              <WidgetInput
                label="שניה"
                value={inputValueMetropolitanRingTwo}
                onChange={handleChangeMetropolitanRingTwo}
                widthProp="117px"
                fontSizeTitle="14px"
                paddingProp="0px 20px"
              />
            </div>
            <div className="model-2-frame-37">
              <WidgetInput
                label="שלישית"
                value={inputValueMetropolitanRingThree}
                onChange={handleChangeMetropolitanRingThree}
                widthProp="117px"
                fontSizeTitle="14px"
                paddingProp="0px 20px"
              />
              <WidgetInput
                label="רביעית"
                value={inputValueMetropolitanRingFour}
                onChange={handleChangeMetropolitanRingFour}
                widthProp="117px"
                fontSizeTitle="14px"
                paddingProp="0px 20px"
              />
            </div>
          </DivColumn>
        </DivColumn>
      </DivColumn>
    );
  }

  function model_design_4() {
    return (
      <div className="model-3-frame">
        {model_design_4_1()}
        {model_design_4_2()}
        {model_design_4_3()}
      </div>
    );
  }

  function model_design_4_1() {
    return (
      <>
        <DivColumn className="model-3-frame-2">
          <DivColumn className="model-3-frame-3">
            <ParagraphText className="model-text-10">עלויות ₪</ParagraphText>
            <WidgetInput
              label="עלות לדונם"
              value={utilsUnit.formatNumberWithCommas(inputValueCostPerAcre)}
              onChange={handleChangeCostPerAcre}
              heightProp="51px"
              widthProp="546px"
              paddingProp="0px 20px"
              backgoundColortProp="#FFF"
            />
          </DivColumn>
          <DivColumn className="model-3-frame-5">
            <DivColumn className="model-3-frame-6">
              <ParagraphText className="model-text-9">עלות מסוף קטן</ParagraphText>
              <div className="model-3-frame-7">
                <WidgetInput label={workMonthsText} value={inputValueMonthsOfWorkOne} onChange={handleChangeMonthsOfWorkOne} widthProp="258px" />
                <WidgetInput
                  label={buildingCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfBuildingsOne)}
                  onChange={handleChangeCostOfBuildingsOne}
                  widthProp="258px"
                />
              </div>
              <div className="model-3-frame-7">
                <WidgetInput
                  label={monthlyTrafficArrangementCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfTrafficOne)}
                  onChange={handleChangeCostOfTrafficOne}
                  widthProp="258px"
                />
                <WidgetTextViewData
                  title={totalTrafficArrangementCostText}
                  text={utilsUnit.formatNumberWithCommaWithShekel(getTotalPriceForTrafficArrangementsPerTerminalTypeNIS(1))}
                  widthProp="258px"
                />
              </div>
            </DivColumn>
          </DivColumn>
        </DivColumn>
      </>
    );
  }

  function model_design_4_2() {
    return (
      <>
        <DivColumn className="model-3-frame-8">
          <DivColumn className="model-3-frame-5">
            <DivColumn className="model-3-frame-6">
              <ParagraphText className="model-text-9">עלות מסוף בינוני</ParagraphText>
              <div className="model-3-frame-7">
                <WidgetInput label={workMonthsText} value={inputValuesetInpuMonthsOfWorkTwo} onChange={handleChangeMonthsOfWorkTwo} widthProp="258px" />
                <WidgetInput
                  label={buildingCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfBuildingsTwo)}
                  onChange={handleChangeCostOfBuildingsTwo}
                  widthProp="258px"
                />
              </div>
              <div className="model-3-frame-7">
                <WidgetInput
                  label={monthlyTrafficArrangementCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfTrafficTwo)}
                  onChange={handleChangeCostOfTrafficTwo}
                  widthProp="258px"
                />
                <WidgetTextViewData
                  title={totalTrafficArrangementCostText}
                  text={utilsUnit.formatNumberWithCommaWithShekel(getTotalPriceForTrafficArrangementsPerTerminalTypeNIS(2))}
                  widthProp="258px"
                />
              </div>
            </DivColumn>
          </DivColumn>
          <DivColumn className="model-3-frame-5">
            <DivColumn className="model-3-frame-6">
              <ParagraphText className="model-text-9">עלות מסוף גדול</ParagraphText>
              <div className="model-3-frame-7">
                <WidgetInput label={workMonthsText} value={inputValueMonthsOfWorkThree} onChange={handleChangeMonthsOfWorkThree} widthProp="258px" />
                <WidgetInput
                  label={buildingCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfBuildingsThree)}
                  onChange={handleChangeCostOfBuildingsThree}
                  widthProp="258px"
                />
              </div>
              <div className="model-3-frame-7">
                <WidgetInput
                  label={monthlyTrafficArrangementCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfTrafficThree)}
                  onChange={handleChangeCostOfTrafficThree}
                  widthProp="258px"
                />
                <WidgetTextViewData
                  title={totalTrafficArrangementCostText}
                  text={utilsUnit.formatNumberWithCommaWithShekel(getTotalPriceForTrafficArrangementsPerTerminalTypeNIS(3))}
                  widthProp="258px"
                />
              </div>
            </DivColumn>
          </DivColumn>
        </DivColumn>
      </>
    );
  }

  function model_design_4_3() {
    return (
      <>
        <DivColumn className="model-3-frame-8">
          <DivColumn className="model-3-frame-5">
            <DivColumn className="model-3-frame-6">
              <ParagraphText className="model-text-9">עלות מתקן משולב</ParagraphText>
              <div className="model-3-frame-7">
                <WidgetInput label={workMonthsText} value={inputValueMonthsOfWorkFour} onChange={handleChangeMonthsOfWorkFour} widthProp="258px" />
                <WidgetInput
                  label={buildingCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfBuildingsFour)}
                  onChange={handleChangeCostOfBuildingsFour}
                  widthProp="258px"
                />
              </div>
              <div className="model-3-frame-7">
                <WidgetInput
                  label={monthlyTrafficArrangementCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfTrafficFour)}
                  onChange={handleChangeCostOfTrafficFour}
                  widthProp="258px"
                />
                <WidgetTextViewData
                  title={totalTrafficArrangementCostText}
                  text={utilsUnit.formatNumberWithCommaWithShekel(getTotalPriceForTrafficArrangementsPerTerminalTypeNIS(4))}
                  widthProp="258px"
                />
              </div>
            </DivColumn>
          </DivColumn>
          <DivColumn className="model-3-frame-5">
            <DivColumn className="model-3-frame-6">
              <ParagraphText className="model-text-9">עלות חניון לילה</ParagraphText>
              <div className="model-3-frame-7">
                <WidgetInput label={workMonthsText} value={inputValueMonthsOfWorkFive} onChange={handleChangeMonthsOfWorkFive} widthProp="258px" />
                <WidgetInput
                  label={buildingCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfBuildingsFive)}
                  onChange={handleChangeCostOfBuildingsFive}
                  widthProp="258px"
                />
              </div>
              <div className="model-3-frame-7">
                <WidgetInput
                  label={monthlyTrafficArrangementCostText}
                  value={utilsUnit.formatNumberWithCommas(inputValueCostOfTrafficFive)}
                  onChange={handleChangeCostOfTrafficFive}
                  widthProp="258px"
                />
                <WidgetTextViewData
                  title={totalTrafficArrangementCostText}
                  text={utilsUnit.formatNumberWithCommaWithShekel(getTotalPriceForTrafficArrangementsPerTerminalTypeNIS(5))}
                  widthProp="258px"
                />
              </div>
            </DivColumn>
          </DivColumn>
        </DivColumn>
      </>
    );
  }

  function ModelDesignFrameText({ title, text }) {
    return (
      <DivColumn className="model-2-frame-16">
        <ParagraphText className="model-text-4">{title}</ParagraphText>
        {text === "" ? <></> : <ParagraphText className="model-text-5">{text}</ParagraphText>}
      </DivColumn>
    );
  }

  function GaugeUI({ numberText }) {
    return (
      <div
        className="model-2-frame-19"
        style={{
          backgroundColor: utilsUnit.getGaugeNumberBackgroundColor(numberText),
        }}
      >
        <ParagraphText
          className="model-text-6"
          style={{
            color: utilsUnit.getGaugeNumberTextColor(numberText),
          }}
        >
          {numberText}
        </ParagraphText>
      </div>
    );
  }

  function alert_dialogs() {
    return (
      <>
        <WidgetAlertDialogEdit isOpen={isDialogEditOpen} onApprove={() => closeEditAlertDialog(true)} onCancel={() => closeEditAlertDialog(false)} />
        <WidgetAlertDialogSave isOpen={isDialogSaveOpen} onCancel={closeSaveAlertDialog} />
      </>
    );
  }
}

export default PageModelInfo;
