/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  Auth: {
    Cognito: {
      userPoolId: "il-central-1_L3zasropE",
      userPoolClientId: "v7flg1iqb6u6fge3mebmr9od3",
      loginWith: {
        oauth: {
          domain: "planausersprod1.auth.il-central-1.amazoncognito.com",
          scopes: ["openid", "email", "phone", "profile", "aws.cognito.signin.user.admin"],
          redirectSignIn: ["https://www.terminals.plana.buspro.org.il/", "http://localhost:3000/"],
          redirectSignOut: ["https://www.terminals.plana.buspro.org.il/", "http://localhost:3000/"],
          responseType: "code",
        },
      },
    },
  },
};

export default awsmobile;
