// Third-Party Libraries
import styled from "styled-components";

export const DivRtl = styled.div`
  direction: rtl;
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WidgetMarginHeight = styled.div`
  height: ${(props) => props.height || "0px"};
`;

export const WidgetMarginWidth = styled.div`
  width: ${(props) => props.width || "0px"};
`;

export const ParagraphText = styled.p`
  text-align: right;
  margin: 0;
`;
