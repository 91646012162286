// Core React Imports
import React, { useState } from "react";

// Third-Party Libraries
import { GoogleMap, LoadScript, MarkerF, InfoWindow } from "@react-google-maps/api";

// Local Imports
import { DivColumn, ParagraphText } from "../../styles/SharedStyles";
import ConstantsStrings from "../../constants/ConstantsStrings";
import ConstantsImages from "../../constants/ConstantsImages";

// CSS/Stylesheets
import "./WidgetMapCss.css";

function WidgetMap({ centerProp, heightProp, widthProp, markerId, markerTitle, markerLat, markerLng }) {
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = () => {
    setSelectedMarker({
      lat: markerLat,
      lng: markerLng,
    });
  };

  const handleCloseClick = () => {
    setSelectedMarker(null);
  };

  return (
    <LoadScript googleMapsApiKey={ConstantsStrings.GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={{
          height: heightProp,
          width: widthProp,
          borderRadius: "15px",
          overflow: "hidden",
        }}
        center={centerProp}
        zoom={14}
      >
        {markerId && (
          <>
            <MarkerF
              key={markerId}
              position={{ lat: markerLat, lng: markerLng }}
              title={markerTitle}
              onClick={handleMarkerClick}
              options={{ icon: ConstantsImages.PARKING_IMAGE }}
            />
            {selectedMarker && (
              <InfoWindow position={selectedMarker} onCloseClick={handleCloseClick}>
                <DivColumn className="info-window-frame">
                  <ParagraphText className="info-window-title" style={{ textAlign: "center" }}>
                    {markerTitle}
                  </ParagraphText>
                  <ParagraphText className="info-window-text">{markerId}</ParagraphText>
                </DivColumn>
              </InfoWindow>
            )}
          </>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default WidgetMap;
