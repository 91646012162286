// Core React Imports
import { useNavigate } from "react-router-dom";

// Local Imports
import ConstantsImages from "../constants/ConstantsImages";

class UtilsUnit {
  navigate = useNavigate();

  regexDecimalOneNumber = /^\d*\.?\d{0,1}$/;
  regexDecimalTwoNumber = /^\d*\.?\d{0,2}$/;
  regexInt = /^\d*$/;
  UserTypeTerminalStatus = {
    NONE: "0",
    VIEWER: "1",
    EDITOR: "2",
  };

  getGaugeImageSrc(number) {
    let imageSrc;

    switch (number) {
      case 1:
        imageSrc = ConstantsImages.GAUGE_1_IMAGE;
        break;
      case 2:
        imageSrc = ConstantsImages.GAUGE_2_IMAGE;
        break;
      case 3:
        imageSrc = ConstantsImages.GAUGE_3_IMAGE;
        break;
      case 4:
        imageSrc = ConstantsImages.GAUGE_4_IMAGE;
        break;
      case 5:
        imageSrc = ConstantsImages.GAUGE_5_IMAGE;
        break;
      default:
        imageSrc = ConstantsImages.GAUGE_5_IMAGE;
    }

    return imageSrc;
  }

  getGaugeNumberBackgroundColor(number) {
    let backgroundColor;

    switch (number) {
      case 1:
        backgroundColor = "#B1C1FA";
        break;
      case 2:
        backgroundColor = "#7E9AF8";
        break;
      case 3:
        backgroundColor = "#486FE9";
        break;
      case 4:
        backgroundColor = "#1C49D2";
        break;
      case 5:
        backgroundColor = "#19209C";
        break;
      default:
        backgroundColor = "#19209C";
    }

    return backgroundColor;
  }

  getGaugeNumberTextColor(number) {
    let textColor;

    switch (number) {
      case 1:
        textColor = "#3E417A";
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        textColor = "#FFF";
        break;
      default:
        textColor = "#FFF";
    }

    return textColor;
  }

  formatNumberWithCommas = (value) => {
    // Remove non-digit characters except the decimal point
    const cleanedValue = value.toString().replace(/[^0-9.]/g, "");

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = cleanedValue.split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine formatted integer and decimal parts, ensuring decimal part is present if it exists
    return decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };

  // Function to remove commas from a formatted number
  removeCommas = (value) => {
    // Remove commas and keep decimal points
    return value.replace(/,/g, "");
  };

  formatNumberWithShekel = (num) => {
    return `₪${num.toLocaleString()}`;
  };

  formatNumberWithPercent = (num) => {
    return `${num.toLocaleString()}%`;
  };

  formatNumberWithCommaWithShekel = (num) => {
    return this.formatNumberWithShekel(this.formatNumberWithCommas(num));
  };

  removePercentageSign = (text) => {
    return text.replace("%", "");
  };

  toNumberWithoutTrailingZeros(num) {
    return parseFloat(num);
  }

  getMeasureValue(serial, list, serialKey, valueKey) {
    const measure = list.find((measure) => measure[serialKey] === serial);
    return measure ? measure[valueKey] : "";
  }

  generateNumberArray = (min, max) => {
    const result = [];

    for (let i = min; i <= max; i++) {
      result.push({ number: i.toString() });
    }

    return result;
  };

  formatDate(inputDateString) {
    // Create a Date object from the input string
    const date = new Date(inputDateString);

    // Format the date as dd.mm.yyyy
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  formatDateHour(inputDateString) {
    // Create a Date object from the input string
    const date = new Date(inputDateString);

    // Format the time as hh:mm
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  areJSONsEqualLoose(json1, json2) {
    return JSON.stringify(this.normalizeValues(json1)) === JSON.stringify(this.normalizeValues(json2));
  }

  normalizeValues(obj) {
    return JSON.parse(JSON.stringify(obj, (key, value) => (typeof value === "number" ? String(value) : value)));
  }

  getUserID(userData) {
    return userData.Username;
  }

  getUserName(userData) {
    const userAttributes = this.getUserAttributes(userData);

    const givenName = userAttributes.find((attr) => attr.Name === "given_name")?.Value;
    const familyName = userAttributes.find((attr) => attr.Name === "family_name")?.Value;

    const fullName = `${givenName || ""} ${familyName || ""}`.trim();

    return fullName;
  }

  getUserAttributes(userData) {
    return userData.UserAttributes;
  }

  getUserTypeTerminal(userData) {
    return this.getUserAttributes(userData)?.find((attr) => attr.Name === "custom:UserTypeTerminal")?.Value;
  }

  checkUserAuthorization(userData, userTypeCheck) {
    if (this.getUserAttributes(userData) === undefined) {
      this.goToRootAndClearHistory();
      return false;
    }

    if (this.getUserTypeTerminal(userData) && userTypeCheck(userData)) {
      return true;
    } else {
      alert("אין הרשאה להיכנס");
      return false;
    }
  }

  checkUserAuthorizationViewerEditor(userData) {
    return this.checkUserAuthorization(userData, (user) => this.viewerUser(user) || this.editorUser(user));
  }

  checkUserAuthorizationEditor(userData) {
    return this.checkUserAuthorization(userData, (user) => this.editorUser(user));
  }

  viewerUser(userData) {
    return this.getUserTypeTerminal(userData) === this.UserTypeTerminalStatus.VIEWER;
  }

  editorUser(userData) {
    return this.getUserTypeTerminal(userData) === this.UserTypeTerminalStatus.EDITOR;
  }

  goToRootAndClearHistory() {
    this.navigate("/", { replace: true });
  }
}

export default UtilsUnit;
