// Core React Imports
import React, { useState, useMemo, useEffect, useRef } from "react";

// Third-Party Libraries
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

// Local Imports
import ApiService from "../../data/ApiService";
import UtilsUnit from "../../utils/UtilsUnit";
import ConstantsPages from "../../constants/ConstantsPages";
import ConstantsImages from "../../constants/ConstantsImages";
import { useLoading } from "../../widgets/loadings/WidgetLoading";
import WidgetDropdown from "../../widgets/dropdowns/WidgetDropdown";
import WidgetMap from "../../widgets/maps/WidgetMap";
import { DivColumn, DivRow, WidgetMarginWidth, ParagraphText } from "../../styles/SharedStyles";

// CSS/Stylesheets
import "./PageFilterCss.css";

function PageFilter() {
  const utilsUnit = new UtilsUnit();

  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const userData = useSelector((state) => state.user.userData);
  const [data, setData] = useState([]);
  const [dataIsReceived, setDataIsReceived] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);
  const [selectedTerminal, setSelectedTerminal] = useState(null);

  const showLoadingRef = useRef(showLoading);
  const hideLoadingRef = useRef(hideLoading);

  // Fetch data on component mount
  useEffect(() => {
    if (!utilsUnit.checkUserAuthorizationViewerEditor(userData)) return;

    fetchData();
  }, []);

  const fetchData = async () => {
    showLoadingRef.current();

    try {
      const apiService = new ApiService();
      const fetchedData = await apiService.getDataForFiltering();

      setData(fetchedData); // Set fetched data
      setDataIsReceived(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoadingRef.current();
    }
  };

  // Unique Districts
  const uniqueDistricts = useMemo(() => {
    const filteredData = Object.values(
      data.reduce((acc, item) => {
        if (!acc[item.districtMOT]) {
          acc[item.districtMOT] = item;
        }
        return acc;
      }, {})
    );

    // Sort by ASC
    return filteredData.sort((a, b) => a.districtMOT.localeCompare(b.districtMOT));
  }, [data]); // Depend on data

  // Unique Municipalities based on selectedDistrict
  const uniqueMunicipalities = useMemo(() => {
    if (!selectedDistrict) {
      return [];
    }

    const filteredData = Object.values(
      data
        .filter((item) => item.districtMOT === selectedDistrict.districtMOT)
        .reduce((acc, item) => {
          if (!acc[item.municipalityName]) {
            acc[item.municipalityName] = item;
          }
          return acc;
        }, {})
    );

    // Sort by ASC
    return filteredData.sort((a, b) => a.municipalityName.localeCompare(b.municipalityName));
  }, [selectedDistrict, data]); // Depend on both `selectedDistrict` and `data`

  // Unique Terminals based on selectedMunicipality
  const uniqueTerminals = useMemo(() => {
    if (!selectedMunicipality) {
      return [];
    }

    const filteredData = Object.values(
      data
        .filter((item) => item.municipalityName === selectedMunicipality.municipalityName)
        .reduce((acc, item) => {
          if (!acc[item.terminalName]) {
            acc[item.terminalName] = item;
          }
          return acc;
        }, {})
    );

    // Sort by ASC
    return filteredData.sort((a, b) => a.terminalName.localeCompare(b.terminalName));
  }, [selectedMunicipality, data]); // Depend on both `selectedMunicipality` and `data`

  const handleSelectDropdownDistrict = (option) => {
    setSelectedDistrict(option);
    setSelectedMunicipality(null); // Reset municipality selection to placeholder
    setSelectedTerminal(null); // Reset terminal selection to placeholder
  };

  const handleSelectDropdownMunicipality = (option) => {
    setSelectedMunicipality(option);
    setSelectedTerminal(null); // Reset terminal selection to placeholder
  };

  const handleSelectDropdownTerminal = (option) => {
    setSelectedTerminal(option);
  };

  const handleSearchFrameClick = () => {
    if (selectedTerminal) {
      navigate(ConstantsPages.PAGE_TERMINAL_INFO, {
        state: { terminalID: selectedTerminal["terminalID"] },
      });
    } else {
      alert("יש לבחור מסוף");
    }
  };

  const handleModelFrameClick = () => {
    navigate(ConstantsPages.PAGE_MODEL_INFO);
  };

  // Return JSX
  if (!dataIsReceived) {
    return <div />;
  }

  return (
    <div className="div-main-2">
      <div style={{ width: "1920px", height: "100vh" }}>
        <DivRow>
          {search_terminal_ui()}
          <WidgetMarginWidth width="98px" />
          {map_n_model_info_nav_ui()}
        </DivRow>
      </div>
    </div>
  );

  function search_terminal_ui() {
    return (
      <DivColumn className="filter-frame-1" style={{ display: "inline-flex" }}>
        <DivRow style={{ alignItems: "center" }}>
          <ReactSVG src={ConstantsImages.TRANSPORTATION_IMAGE} />
          <WidgetMarginWidth width="10px" />
          <ParagraphText className="model-text">מודל לתיעדוף מתקני תשתית לתפעול תח״צ באוטובוסים</ParagraphText>
        </DivRow>
        <DivColumn className="filter-frame-2">
          <ParagraphText className="search-terminal-text">חיפוש מסוף</ParagraphText>
          <DivColumn className="filter-frame-3">
            <WidgetDropdown
              options={uniqueDistricts}
              labelKey="districtMOT"
              title="מחוז"
              onSelect={handleSelectDropdownDistrict}
              selectedOptionProp={selectedDistrict}
              heightProp="60px"
            />
            <WidgetDropdown
              options={uniqueMunicipalities}
              labelKey="municipalityName"
              title="רשות"
              onSelect={handleSelectDropdownMunicipality}
              selectedOptionProp={selectedMunicipality}
              heightProp="60px"
            />
            <DivRow className="filter-frame-4">
              <WidgetDropdown
                options={uniqueTerminals}
                labelKey="terminalName"
                title="מסוף"
                onSelect={handleSelectDropdownTerminal}
                selectedOptionProp={selectedTerminal}
                heightProp="60px"
              />
              <div className="search-frame" onClick={handleSearchFrameClick}>
                <ReactSVG src={ConstantsImages.SEARCH_IMAGE} />
              </div>
            </DivRow>
          </DivColumn>
        </DivColumn>
      </DivColumn>
    );
  }

  function map_n_model_info_nav_ui() {
    return (
      <div style={{ display: "flex" }}>
        <WidgetMap
          centerProp={{
            lat: 32.0853,
            lng: 34.7818,
          }}
          heightProp="886px"
          widthProp="1008px"
        />
        {utilsUnit.editorUser(userData) && (
          <div className="model-frame" onClick={handleModelFrameClick}>
            <ReactSVG src={ConstantsImages.MODEL_IMAGE} style={{ margin: "15px" }} />
          </div>
        )}
      </div>
    );
  }
}

export default PageFilter;
