// Core React Imports
import React, { createContext, useState, useContext } from "react";

// CSS/Stylesheets
import "./WidgetLoadingCss.css";

// Create a context for loading state
const LoadingContext = createContext();

// Create a provider component
export const WidgetLoading = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  // Function to show loading spinner
  const showLoading = () => setIsLoading(true);

  // Function to hide loading spinner
  const hideLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </LoadingContext.Provider>
  );
};

// Custom hook to use the loading context
export const useLoading = () => useContext(LoadingContext);
