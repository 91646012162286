// Core React Imports
import React from "react";

// Local Imports
import { DivColumn, ParagraphText } from "../../../styles/SharedStyles";

// CSS/Stylesheets
import "./WidgetInputSecondCss.css";
import "../../../styles/SharedStylesCss.css";

const WidgetInputSecond = ({
  value,
  onChange,
  heightProp = "44px",
  widthProp = "80px",
  textAlignProp = "center",
  showOverlayText = false,
  paddingRightProp = "0px",
  rightPropText = "auto",
}) => {
  return (
    <DivColumn className="model-2-frame-11" style={{ height: heightProp, width: widthProp }}>
      <div className="model-2-frame-12">
        <DivColumn className="model-2-frame-13" style={{ position: "relative" }}>
          <input type="text" className="text-edit" style={{ textAlign: textAlignProp, paddingRight: paddingRightProp }} value={value} onChange={onChange} />
          {showOverlayText && (
            <ParagraphText className="overlay-text-1" style={{ right: rightPropText }}>
              %
            </ParagraphText>
          )}
        </DivColumn>
      </div>
    </DivColumn>
  );
};

export default WidgetInputSecond;
