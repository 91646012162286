class ApiService {
  baseURL = "https://yhnj675lni.execute-api.il-central-1.amazonaws.com/prod";

  // Get - start
  async getUserData(code) {
    const response = await fetch(`${this.baseURL}/get_user_data`, {
      method: "GET",
      headers: {
        Authorization: code,
      },
    });

    checkResponseStatus(response, "אין הרשאה להיכנס");

    return response.json();
  }

  async getDataForFiltering() {
    const response = await fetch(`${this.baseURL}/get_data_for_filtering`, {
      method: "GET",
    });

    checkResponseStatus(response, "טעינת רשימת המסופים נכשלה");

    return response.json();
  }

  async getModelParameters() {
    const response = await fetch(`${this.baseURL}/get_model_parameters`, {
      method: "GET",
    });

    checkResponseStatus(response, "קבלת המידע על המודל נכשלה");

    return response.json();
  }
  // Get - end

  // Post - start
  async postTerminalInfo(terminalID) {
    const response = await fetch(`${this.baseURL}/post_terminal_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ terminalID }),
    });

    checkResponseStatus(response, "קבלת המידע על המסוף נכשלה");

    return response.json();
  }

  async postTerminalUpdateData(data) {
    const response = await fetch(`${this.baseURL}/post_terminal_update_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    checkResponseStatus(response, "עדכון המסוף נכשל");

    return response;
  }

  async postModelParametersUpdateData(data) {
    const response = await fetch(`${this.baseURL}/post_model_parameters_update_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    checkResponseStatus(response, "עדכון המודל נכשל");

    return response;
  }
  // Post - end
}

function checkResponseStatus(response, errorMessage) {
  if (!response.ok) {
    alert(errorMessage);

    throw new Error("Network response was not ok");
  }
}

export default ApiService;
