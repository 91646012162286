// Core React Imports
import React from "react";

// Local Imports
import WidgetInputSecond from "../widget_input_second/WidgetInputSecond";

const WidgetInputPercent = ({ value, onChange, heightProp = "44px", widthProp = "80px", paddingRightProp = "80px", rightPropText = "21px" }) => {
  return (
    <WidgetInputSecond
      value={value}
      onChange={onChange}
      heightProp={heightProp}
      widthProp={widthProp}
      textAlignProp="right"
      showOverlayText={true}
      paddingRightProp={paddingRightProp}
      rightPropText={rightPropText}
    />
  );
};

export default WidgetInputPercent;
