// Core React Imports
import React, { useState, useRef, useEffect } from "react";

// Third-Party Libraries
import { ReactSVG } from "react-svg";

// Local Imports
import { DivColumn, DivRow, ParagraphText, WidgetMarginHeight } from "../../styles/SharedStyles";
import ConstantsImages from "../../constants/ConstantsImages";

// CSS/Stylesheets
import "./WidgetDropdownCss.css";

export const WidgetDropdownTypeEnum = {
  DEFAULT: "default",
  NUMBER: "number",
};

const WidgetDropdown = ({
  options,
  onSelect,
  labelKey,
  title,
  selectedOptionProp,
  titleColorProp = "#333333",
  textColorProp = "#373734",
  backgroundColorProp = "#fff",
  heightProp = "auto",
  widthProp = "100%",
  borderRadiusProp = "5px",
  fontSizeTitle = "14px",
  fontWeightText = "900",
  fontFamilyText = "Almoni DL AAA",
  arrowImage = ConstantsImages.ARROW_IMAGE,
  style = {},
  type = WidgetDropdownTypeEnum.DEFAULT,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Refs for dropdown element and list
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  // Toggle dropdown visibility
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Handle item selection
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (onSelect) {
      onSelect(option);
    }
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Reset selected option when the external prop changes
  useEffect(() => {
    setSelectedOption(selectedOptionProp);
  }, [selectedOptionProp]);

  // Scroll selected item into view at the top of the list
  useEffect(() => {
    if (isOpen && listRef.current && selectedOption) {
      const selectedItem = listRef.current.querySelector(".selected");
      if (selectedItem) {
        listRef.current.scrollTop = selectedItem.offsetTop;
      }
    }
  }, [isOpen, selectedOption]);

  return (
    <div className="custom-dropdown" ref={dropdownRef} style={style}>
      {type === WidgetDropdownTypeEnum.DEFAULT ? (
        <div
          className="dropdown-header"
          style={{
            height: heightProp,
            width: widthProp,
            backgroundColor: backgroundColorProp,
            borderRadius: borderRadiusProp,
          }}
          onClick={handleToggle}
        >
          <div>
            <ParagraphText className="dropdown-header-title" style={{ color: titleColorProp, fontSize: fontSizeTitle }}>
              {title}
            </ParagraphText>
            {selectedOption && (
              <DivColumn>
                <WidgetMarginHeight height="2px" />
                <ParagraphText
                  className="dropdown-header-text"
                  style={{
                    color: textColorProp,
                    fontWeight: fontWeightText,
                    fontFamily: fontFamilyText,
                  }}
                >
                  {selectedOption[labelKey]}
                </ParagraphText>
              </DivColumn>
            )}
          </div>
          <ReactSVG src={arrowImage} />
        </div>
      ) : (
        <div className="dropdown-header-2" onClick={handleToggle}>
          <DivRow className="dropdown-header-2-inner">
            {selectedOption && <ParagraphText className="dropdown-header-title-2">{selectedOption[labelKey]}</ParagraphText>}
            <ReactSVG src={arrowImage} />
          </DivRow>
        </div>
      )}
      {isOpen && options.length > 0 && (
        <div className="dropdown-list" style={{ width: widthProp }} ref={listRef}>
          {options.map((option, index) => (
            <ParagraphText
              key={index}
              className={`dropdown-item ${selectedOption && selectedOption[labelKey] == option[labelKey] ? "selected" : ""}`}
              onClick={() => handleSelect(option)}
            >
              {option[labelKey]}
            </ParagraphText>
          ))}
        </div>
      )}
    </div>
  );
};

export default WidgetDropdown;
