// Core React Imports
import React from "react";

// Local Imports
import { DivColumn, ParagraphText } from "../../../styles/SharedStyles";

// CSS/Stylesheets
import "./WidgetInputsFromToCss.css";

const WidgetInputsFromTo = ({ valueFromInput, valueToInput, onChangeFromInput, onChangeToInput }) => {
  return (
    <DivColumn className="model-2-frame-20">
      <div className="model-2-frame-21">
        <DivColumn className="model-2-frame-22">
          <div className="model-2-frame-23">
            <DivColumn className="model-2-frame-24">
              <ParagraphText className="model-text-7">מ -</ParagraphText>
              <input type="text" className="text-edit" value={valueFromInput} onChange={onChangeFromInput} />
            </DivColumn>
          </div>
        </DivColumn>
        <DivColumn className="model-2-frame-25">
          <div className="model-2-frame-26">
            <DivColumn className="model-2-frame-24">
              <ParagraphText className="model-text-7">עד -</ParagraphText>
              <input type="text" className="text-edit" value={valueToInput} onChange={onChangeToInput} />
            </DivColumn>
          </div>
        </DivColumn>
      </div>
    </DivColumn>
  );
};

export default WidgetInputsFromTo;
