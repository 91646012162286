// Core React Imports
import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// Local Imports
import PageWelcome from "./pages/page_welcome/PageWelcome.jsx";
import PageFilter from "./pages/page_filter/PageFilter.jsx";
import PageTerminalInfo from "./pages/page_terminal_info/PageTerminalInfo.jsx";
import PageModelInfo from "./pages/page_model_info/PageModelInfo.jsx";
import { DivRtl } from "./styles/SharedStyles";
import { WidgetLoading } from "./widgets/loadings/WidgetLoading";
import ConstantsPages from "./constants/ConstantsPages.js";

// CSS/Stylesheets
import "./App.css";

function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <WidgetLoading>
        <DivRtl>
          <div className="div-main">
            <Routes>
              <Route path={ConstantsPages.PAGE_WELCOME} element={<PageWelcome navigate={navigate} />} />
              <Route path={ConstantsPages.PAGE_FILTER} element={<PageFilter navigate={navigate} />} />
              <Route path={ConstantsPages.PAGE_TERMINAL_INFO} element={<PageTerminalInfo navigate={navigate} />} />
              <Route path={ConstantsPages.PAGE_MODEL_INFO} element={<PageModelInfo navigate={navigate} />} />
            </Routes>
          </div>
        </DivRtl>
      </WidgetLoading>
    </div>
  );
}

export default App;
