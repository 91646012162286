// Core React Imports
import React from "react";

// Local Imports
import { DivColumn, ParagraphText } from "../../../styles/SharedStyles";

// CSS/Stylesheets
import "./WidgetAlertDialogEditCss.css";

const WidgetAlertDialogEdit = ({ isOpen, onCancel, onApprove }) => {
  if (!isOpen) return null;

  return (
    <div className="alert-dialog-background">
      <DivColumn className="alert-dialog-overlay-edit">
        <DivColumn className="alert-dialog-overlay-2-edit">
          <div className="alert-dialog-overlay-3-edit">
            <ParagraphText className="alert-dialog-overlay-text-edit" style={{ textAlign: "center" }}>
              שים/י לב
            </ParagraphText>
          </div>
          <ParagraphText className="alert-dialog-overlay-text-2-edit" style={{ textAlign: "center" }}>
            בחרת לצאת ממצב עריכה ללא שמירת השינויים
          </ParagraphText>
        </DivColumn>
        <div className="alert-dialog-overlay-4-edit">
          <DivColumn className="alert-dialog-overlay-5-edit" style={{ height: "50px" }} onClick={onApprove}>
            <ParagraphText className="alert-dialog-overlay-text-3-edit" style={{ textAlign: "center" }}>
              יציאה ללא שמירה
            </ParagraphText>
          </DivColumn>
          <DivColumn className="alert-dialog-overlay-6-edit" style={{ height: "50px" }} onClick={onCancel}>
            <ParagraphText className="alert-dialog-overlay-text-3-edit" style={{ textAlign: "center" }}>
              חזרה למצב עריכה
            </ParagraphText>
          </DivColumn>
        </div>
      </DivColumn>
    </div>
  );
};

export default WidgetAlertDialogEdit;
